import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountService } from '../shared/account/account.service';

@Component({
    selector: 'mvta-messages',
    templateUrl: 'messages.component.html'
})
export class MessagesComponent implements OnInit {

    accountToken;
    messages;
    message;
    showLogin = false;
    redirectTo = '';
    loading = true;

    constructor(private route: ActivatedRoute, private accountService: AccountService) {
        this.accountToken = {};
        this.messages = {
            '404': {
                title: '¡Lo sentimos!',
                html: 'La página que solicitaste no existe'
            },
            'no-encontrado': {
                title: 'Artículo no Encontrado',
                html: 'El artículo que buscas no fue encontrado.'
            },
            'no-logistica': {
                title: 'Lo sentimos, actualmente no aceptamos pedidos.',
                html: 'Por favor ponte en contacto con servicio a cliente, disculpa el inconveniente.'
            },
            'no-metodos-pago': {
                title: 'Lo sentimos, actualmente no aceptamos pedidos.',
                html: 'Hay un inconveniente con los procesadores de pagos. Por favor contacta a soporte, disculpa el inconveniente'
            },
            'no-token': {
                title: '¡Lo Sentimos!',
                html: 'Hemos encontrado un problema con la tienda, por favor contacta con soporte.'
            },
            'no-orden': {
                title: 'Orden no Encontrada',
                html: 'La orden que buscas no ha sido encontrada.'
            },
            'login': {
                title: 'Inicia Sesión',
                html: 'No hemos identificado tu usuario. Por favor inicia sesión.'
            }
        };
        this.message = {};
    }

    ngOnInit() {
        this.route.params.subscribe((params) => {
            this.loading = false;
            if (params.messageId !== undefined) {
                if (this.messages.hasOwnProperty(params.messageId)) {
                    this.message = this.messages[params.messageId];
                    return;
                }
            }
            this.message = this.messages['404'];
        });
        this.route.queryParams.subscribe((qParams) => {
            this.showLogin = qParams['login'] || false;
            this.redirectTo = qParams['redirectTo'] || '';
            const token = qParams['token'] || '';
            if (token.length > 3) {
                this.accountService.setAccessToken(token);
            }
        });
    }

}
