import { Address, DateAt, I18n } from '../shared/interfaces/common.interface';

export const ORDER_TYPE_STANDARD = 'standard';
export const ORDER_TYPE_LOCAL_PICKUP = 'local_pickup';

export const ORDER_CANCELLED = 'cancelled';
export const ORDER_PENDING_PAYMENT = 'payment';
export const ORDER_EXPIRED = 'expired';
export const ORDER_COMPLETED = 'completed';
export const ORDER_PENDING_APPROVAL = 'approval';
export const ORDER_PREAUTHORIZED = 'preauthorized';
export const ORDER_REJECTED = 'rejected';

export const PACKAGE_STATUS = {
    PENDING: 'pending',
    ROUTED: 'routed',
    DELIVERED: 'delivered'
};

export interface Destination {
    name?: string;
    addressLine1?: string;
    addressLine2?: string;
    addressLine3?: string;
    city?: string;
    state?: string;
    country?: string;
    primaryPhone?: string;
    secondaryPhone?: string;
    postalCode?: string;
    shippingMethod?: any;
}

export interface DeliveryCostDetails {
    weight?: number;
    price?: number;
    tax?: number;
    total?: number;
}

export interface Dimensions {
    weight?: number;
    height?: number;
    width?: number;
    length?: number;
}

export interface OrderItem {
    muventaSKU?: string;
    vendorSKU?: string;
    name?: string;
    attributes?: string;
    pType?: string;
    slug?: string;
    vendor?: string;
    vendorBusinessName?: string;
    dimensions?: Dimensions;
    quantity?: number;
    vendorPrice?: number;
    muventaPrice?: number;
    taxCollected?: number;
    profit?: number;
    total?: number;
    listPrice?: ItemListPrice | null;
}

export interface ItemListPrice {
  basePrice: number;
  calculatedPrice: boolean;
  price: number;
  tax: number;
}

export interface Package {
    version?: number;
    createdAt?: DateAt;
    updatedAt?: DateAt;
    createdBy?: string;
    modifiedBy?: string;
    id?: string;
    vendor?: string;
    orderId?: string;
    deliveryMode?: string;
    carrier?: string;
    trackingNum?: string;
    trackingUrl?: string;
    documentUrl?: string;
    collectedAt?: string;
    willDeliverAt?: string;
    deliveredAt?: string;
    status?: string;
    from?: Destination;
    to?: Destination;
    customerName?: string;
    customerId?: string;
    weight?: number;
    deliveryCost?: number;
    itemCost?: number;
    taxCollected?: number;
    profit?: number;
    deliveryCostDetails?: DeliveryCostDetails;
    items?: OrderItem[];
}

export interface Price {
    mxn?: number;
    usd?: number;
    beforeMxn?: number;
    beforeUsd?: number;
    mxnSalesman?: number;
    mxnSalesmanFee?: number;
}

export interface Attributes {
    attribute1?: string;
    attribute2?: string;
    attribute3?: string;
}

export interface OrderItemDetails {
    image?: string;
    price?: Price;
    qty?: number;
    name?: I18n;
    attributes?: Attributes;
}

export interface Payment {
    gateway?: string;
    url?: string;
    paid?: boolean;
    authorization?: string;
    operationId?: string;
    type?: string;
    card?: string;
    gatewayResponse?: string;
    payPalId?: string;
}

export interface Order {
    version?: number;
    type?: string;
    createdAt?: DateAt;
    updatedAt?: DateAt;
    createdBy?: string;
    modifiedBy?: string;
    id?: string;
    customerId?: string;
    customerEmail?: string;
    customerName?: string;
    salesmanId?: string;
    specialDiscount?: number;
    placedBy?: string;
    referredBy?: string;
    referredName?: string;
    destination?: Destination;
    billing?: Address;
    comments?: string;
    packages?: Package[];
    allPackageItems?: OrderItem[];
    reservations?: any[];
    payment?: any[];
    vendors?: string;
    coupons?: string;
    currency?: string;
    itemSubtotal?: number;
    subtotal?: number;
    taxesCollected?: number;
    deliveryCost?: number;
    deliveryDiscount?: number;
    deliveryTotal?: number;
    txFee?: number;
    eMoney?: number;
    eWallet?: number;
    profit?: number;
    total?: number;
    orderStatus?: string;
    rewardsProcessed?: boolean;
    itemsProcessed?: boolean;
    activatesMembership?: boolean;
}

export interface OrderRewards {
    orderId?: string;
    eWallet?: number;
    referredBy?: string;
    coupon?: string;
}
