export const environment = {
  production: false,
  version: '1.0.0',
  apiUrl: 'https://sandbox-api.muventa.mx',
  mdpUrl: 'https://qpxwtbshg5.execute-api.us-east-1.amazonaws.com/dev',
  sessionStorage: {
    accessToken: 'mvta-chk-access',
    user: 'mvta-chk-user',
    referred: 'mvta-referred'
  },
  localStorage: {
    fingerprint: 'mvta-bfp',
    user: 'mvta-user'
  },
  keenIO: {
    projectId: '589a65df8db53dfda8a86d37',
    writeKey: '9AFEF5C486D678F9B4F53634E3AE0C85C279C515D6C52609EB6FB60466B008F1D319719955A038842148FAC13F79B7D51F36AD245946B192314200DBB3766420A5646D00A3B45FD80F93BB6D2FCFD55066DF132AF32BF73D89D5DD6763290D55'
  }
};
