import { Component, Input } from '@angular/core';

@Component({
    selector: 'mvta-loading-indicator',
    templateUrl: './loading-indicator.component.html',
    styleUrls: ['./loading-indicator.component.scss']
})
export class LoadingIndicatorComponent {

    _indicator: boolean;

    @Input() set indicator(val: boolean) {
        this._indicator = val;
    }

    get indicator(): boolean {
        return this._indicator;
    }

}
