import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'mvta-wait',
  templateUrl: 'wait.component.html'
})
export class WaitComponent implements OnInit {

  loading = true;
  txId = '';
  waitTime = 3000;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams.subscribe((qParams) => {
      this.txId = qParams.id || '';
      this.loading = false;
      this.waitAndClose();
    });

  }

  waitAndClose() {
    setTimeout(() => {
      const opener = window.opener;
      if (opener !== undefined) {
        opener.focus();
      }
      window.close();
    }, this.waitTime);
  }
}
