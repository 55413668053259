import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Angulartics2 } from 'angulartics2';
import { forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
import { Destination, Order, OrderItem, ORDER_COMPLETED, ORDER_PREAUTHORIZED, ORDER_REJECTED, PACKAGE_STATUS, Payment } from '../order/order.interface';
import { OrderService } from '../order/order.service';
import { AccountService } from '../shared/account/account.service';
import { PurchaseService } from './purchase.service';

@Component({
  selector: 'mvta-purchase',
  templateUrl: 'purchase.component.html',
  styleUrls: ['./purchase.component.scss'],
  providers: [PurchaseService, OrderService]
})
export class PurchaseComponent implements OnInit {

  PACKAGE_STATUS = PACKAGE_STATUS;
  ORDER_COMPLETED = ORDER_COMPLETED;
  ORDER_PREAUTHORIZED = ORDER_PREAUTHORIZED;
  ORDER_REJECTED = ORDER_REJECTED;
  packageStatusLabels = {};

  loading = true;
  accountToken;
  orderId = '';
  order: Order;
  orderPayments = [];
  warehouseLocation = null;
  taxesCollected = 0;
  DEFAULT_TAX = 16;

  constructor(private route: ActivatedRoute, private router: Router, private purchaseService: PurchaseService,
    private accountService: AccountService, private orderService: OrderService, private angulartics2: Angulartics2) {
    this.accountToken = {};
    this.packageStatusLabels[PACKAGE_STATUS.PENDING] = 'Pendiente';
    this.packageStatusLabels[PACKAGE_STATUS.ROUTED] = 'En tránsito';
    this.packageStatusLabels[PACKAGE_STATUS.DELIVERED] = 'Entregada';
  }

  ngOnInit() {
    this.checkRouteParams();
  }

  checkRouteParams() {
    forkJoin(
      this.route.queryParams.pipe(take(1)),
      this.route.params.pipe(take(1))
    ).subscribe((arrResponse) => {
      const queryParams = arrResponse[0];
      const params = arrResponse[1];

      this.orderId = params.orderId || '';
      if (this.orderId.length === 0) {
        this.angulartics2.eventTrack.next({
          action: 'purchase',
          properties: {
            developerMessage: 'No order id',
            action: 'noOrderId'
          }
        });
        this.router.navigate(['/mensajes/no-orden']);
        return;
      }

      const schema = queryParams.account || '';
      if (schema.length === 0) {
        this.angulartics2.eventTrack.next({
          action: 'purchase',
          properties: {
            developerMessage: 'There is no account',
            action: 'noAccount'
          }
        });
        this.router.navigate(['/mensajes/no-token']);
        return;
      }

      this.getAccessToken(schema)
        .then(() => {
          this.getOrder();
        })
        .catch((error) => {
          console.error('Can not get access token', error);
          this.angulartics2.eventTrack.next({
            action: 'purchase',
            properties: {
              developerMessage: 'There is no token',
              action: 'noToken'
            }
          });
          this.router.navigate(['/mensajes/no-token']);
          return;
        });
    });
  }

  getOrder() {
    this.orderService.getPublicOrder(this.orderId)
      .subscribe((response) => {
        this.order = response;
        this.warehouseLocation = null;
        if (this.order && this.order.type === 'local_pickup') {
          if (this.order.destination.shippingMethod && this.order.destination.shippingMethod.warehouseLocation) {
            this.warehouseLocation = this.order.destination.shippingMethod.warehouseLocation;
          }
        }
        this.loading = false;
        this.calculateCollectedTaxes();
      }, (error) => {
        console.error('Can not get order', error);
        this.angulartics2.eventTrack.next({
          action: 'purchase',
          properties: {
            developerMessage: 'The order was not found',
            action: 'orderNotFound'
          }
        });
        this.router.navigate(['/mensajes/no-orden']);
        return;
      });
  }

  getAccessToken(acc): Promise<any> {
    acc = acc.replace(new RegExp('-', 'g'), '_');
    return new Promise((resolve, reject) => {
      this.angulartics2.eventTrack.next({
        action: 'purchase',
        properties: {
          developerMessage: 'Looking for account access token',
          action: 'getAccessToken',
          account: acc
        }
      });
      this.purchaseService.getAccessToken(acc)
        .subscribe((response) => {
          this.accountService.setAccessToken(response.jwt);
          this.accountToken = this.accountService.getAccessTokenDetails();
          this.angulartics2.eventTrack.next({
            action: 'purchase',
            properties: {
              developerMessage: 'Account access token found',
              action: 'getAccessToken',
              account: acc
            }
          });

          resolve(response);
        }, (error) => {
          this.angulartics2.eventTrack.next({
            action: 'purchase',
            properties: {
              developerMessage: 'Account access token not found',
              action: 'getAccessToken',
              account: acc,
              error: error
            }
          });
          reject(error);
        });
    });
  }

  onPrint(ev) {
    window.print();
  }

  printAddress(destination: Destination): string {
    let addr = destination;
    if (this.warehouseLocation !== null) {
      addr = this.warehouseLocation.address;
    }
    const fields = [
      addr.addressLine1,
      addr.addressLine2.length ? 'Int ' + addr.addressLine2 : '',
      addr.addressLine3,
      addr.city,
      addr.postalCode,
      addr.state,
      addr.country
    ].filter((f) => f.trim().length !== 0 && f.trim().toLowerCase() !== 'na');

    return fields.join(', ');
  }

  printPaymentAmount(pay: Payment): number {
    let amount = 0;
    try {
      const gwResponse = JSON.parse(pay.gatewayResponse);
      if (pay.gateway === 'paypal') {
        amount = gwResponse.transactions.reduce((accum, t) => {
          const a = Number.parseFloat(String(t.amount.total || 0));
          return accum + a;
        }, 0);
      } else {
        amount = Number.parseFloat(gwResponse.amount + '') || 0;
      }
    } catch (e) {
      return amount;
    }

    return amount;
  }

  printPaymentId(pay: Payment): string {
    let paymentId = '';
    if (pay.gateway === 'paypal') {
      try {
        const gwResponse = JSON.parse(pay.gatewayResponse);
        paymentId = gwResponse.id || '';
      } catch (e) {
        paymentId = '';
      }
    } else {
      paymentId = pay.operationId || '';
    }
    if (paymentId.length > 5) {
      const part1 = paymentId.split('|')[0];
      paymentId = part1.substring(part1.length - 5);
    }

    return paymentId;
  }

  printAttributes(attrs: string): string {
    const sAttrs = attrs.split('-').map((a) => a.trim()).filter((fa) => fa.length && fa !== 'na');
    return sAttrs.length ? sAttrs.join(' - ') : '';
  }

  private calculateCollectedTaxes(): void {
    let collectedTaxes = 0;
    this.order.allPackageItems = (this.order.packages || [])
      .map((p) => p.items)
      .reduce((acc: OrderItem[], items: OrderItem[]) => acc.concat(items), []);

    for (const item of this.order.allPackageItems) {
      const taxPercentage = item.listPrice ? item.listPrice.tax : this.DEFAULT_TAX;
      if (taxPercentage > 0) {
        // Total sin impuestos: total / 1 + (percentage / 100)
        // p.e. 116 con 16% = 116 / 1 + (16 / 100) = 116 / 1.16
        const totalWithoutTax = item.total / (1 + (taxPercentage / 100));
        collectedTaxes += Math.round((item.total - totalWithoutTax) * 100) / 100;
      }
    }

    this.taxesCollected = Math.round(collectedTaxes * 100) / 100;
  }
}
