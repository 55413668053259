import { Location } from '@angular/common';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef, NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Angulartics2 } from 'angulartics2';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { AddressesService } from '../addresses/addresses.service';
import { AccountService } from '../shared/account/account.service';
import { AuthService } from '../shared/auth/auth.service';
import { Address } from '../shared/interfaces/common.interface';
import { MERCADO_PAGO_PAYMENT_MESSAGES } from './gateway-messages.interface';
import { Order, OrderItemDetails, OrderRewards, ORDER_PENDING_PAYMENT, ORDER_TYPE_LOCAL_PICKUP } from './order.interface';
import { OrderService } from './order.service';

declare const OpenPay: any;
declare const Mercadopago: any;
declare const paypal: any;

@Component({
  selector: 'mvta-order',
  templateUrl: 'order.component.html',
  styleUrls: ['./order.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [OrderService, AddressesService]
})
export class OrderComponent implements OnInit {
  @ViewChild('modalWaiting') modalWaiting;
  modalWaitingRef: NgbModalRef;

  accountToken;
  logisticsSettings;
  localPickupSettings;
  localPickupLocation;
  order: Order;
  orderItems: Array<OrderItemDetails>;
  orderId = '';
  loadingOrder = true;
  orderCompleted = false;
  error;

  // Addressess
  addresses = {
    shipping: [],
    billing: []
  };
  shippingSettings = {
    title: 'Dirección de Envío',
    countries: [],
    localPickups: {},
    billing: false
  };
  billingSettings = {
    title: 'Información de Facturación',
    countries: [],
    localPickups: {},
    billing: true
  };
  editShipping = false;
  editBilling = false;
  address: Address;
  billingAddress: Address;
  updatingShipping = false;
  updatingBilling = false;

  // Payment Gateways
  paymentGatewaysConfig;
  availablePayments = {
    openpay: false,
    mercadopago: false,
    safetypay: false,
    paypal: false
  };
  noPaymentsGateways = false;
  paymentMethod = 'creditcard';
  activePanel = 'panelCreditCard';
  loadingConfig = true;

  // Paypal
  paypalButtonCreated = false;
  creatingPaypalButton = false;

  // MercadoPago
  mpPaymentMethods = [];
  mpPaymentMethod = '';

  // Credit Card
  masks = {
    cardNumber: [
      /\d/, /\d/, /\d/, /\d/, ' ',
      /\d/, /\d/, /\d/, /\d/, ' ',
      /\d/, /\d/, /\d/, /\d/, ' ',
      /\d/, /\d/, /\d/, /\d/
    ],
    cardExpiration: [
      /\d/, /\d/,
      ' ', '/', ' ',
      /\d/, /\d/
    ],
    cardCvv: [
      /\d/, /\d/, /\d/, /\d/
    ]
  };
  payment = {
    cardNumber: '',
    cardName: '',
    cardExpiration: '',
    cardCvv: ''
  };
  paying = false;
  requireBilling = false;
  txId = '';

  constructor(private route: ActivatedRoute, private router: Router, private accountService: AccountService,
    private authService: AuthService, private orderService: OrderService, private toastrService: ToastrService,
    private modalService: NgbModal, private domSanitizer: DomSanitizer, private angulartics2: Angulartics2,
    private addressService: AddressesService, private location: Location) {

    this.error = {};
    this.address = {};
    this.billingAddress = {};
    this.localPickupSettings = { enabled: false };
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.orderId = params['orderId'] || '';
      if (!this.orderId || this.orderId.length === 0) {
        Swal.fire({
          title: 'Error',
          text: 'No se ha encontrado la orden',
          type: 'error'
        }).then(() => this.closeOrHome());
        return;
      }

      // Check if user is logged in
      if (!this.authService.isLoggedIn()) {
        if (!this.accountService.isAccessTokenValid()) {
          Swal.fire({
            title: 'Orden expirada',
            text: 'Ésta orden ha expirado, por favor vuelve a crear una.',
            type: 'error',
            confirmButtonText: '¡Sí, lo haré!'
          })
            .then(() => this.closeOrHome());
        } else {
          this.router.navigate(['mensajes/login'], { queryParams: { login: true, redirectTo: '/orden/' + this.orderId } });
        }
        this.angulartics2.eventTrack.next({
          action: 'order',
          properties: {
            developerMessage: 'User not logged in',
            action: 'orderNotUser',
            orderId: this.orderId
          }
        });
        return;
      }

      this.getOrder();
    });
    this.accountToken = this.accountService.getAccessTokenDetails();
  }

  getOrder() {
    this.loadingOrder = true;
    this.orderService.getOrder(this.orderId)
      .subscribe((response) => {
        this.order = response;
        this.loadingOrder = false;
        this.postOrder();
        this.getGatewaysConfig();
      }, (error) => {
        console.error('Can not get the order', error);
        Swal.fire({
          title: 'Error',
          text: 'No se ha encontrado la orden',
          type: 'error'
        }).then(() => this.closeOrHome());
        this.loadingOrder = false;
        this.angulartics2.eventTrack.next({
          action: 'order',
          properties: {
            developerMessage: 'Order not found',
            action: 'orderNotFound',
            orderId: this.orderId,
            orderStatus: this.order.orderStatus
          }
        });
      });
  }

  postOrder() {
    this.orderItems = [].concat.apply([], this.order.packages.map((p) => p.items));
    this.address = Object.assign({}, this.order.destination);
    delete this.address.createdAt;
    delete this.address.updatedAt;
    delete this.order.destination.shippingMethod.warehouseLocation.address['createdAt'];
    delete this.order.destination.shippingMethod.warehouseLocation.address['updatedAt'];

    this.billingAddress = this.order.billing ? Object.assign({}, this.order.billing) : {};
    this.address.isPickup = this.order.type === ORDER_TYPE_LOCAL_PICKUP;
    this.findPickupLocation();

    // Check order status, should be waiting payment
    if (this.order.orderStatus !== ORDER_PENDING_PAYMENT) {
      this.loadingOrder = true;
      Swal.fire({
        title: 'Orden No Válida',
        text: 'Ésta orden no está esperando pago, contacte a soporte.',
        type: 'warning'
      })
        .then(() => this.closeOrHome());
      this.angulartics2.eventTrack.next({
        action: 'order',
        properties: {
          developerMessage: 'The order is not waiting for payment',
          action: 'orderNotPendingPayment',
          orderId: this.orderId,
          orderStatus: this.order.orderStatus
        }
      });
      return;
    }
  }

  getGatewaysConfig() {
    this.loadingConfig = true;
    this.accountService.logisticsSettingsChange.subscribe((response) => {
      if (response === null) {
        // Not loaded yet, wait until next event
        return;
      }
      this.loadingConfig = false;
      this.logisticsSettings = response;
      this.paymentGatewaysConfig = this.logisticsSettings.payment;
      this.localPickupSettings = this.logisticsSettings.settings.localPickups;
      this.findPickupLocation();

      if (this.paymentGatewaysConfig.openpay && OpenPay) {
        OpenPay.setId(this.paymentGatewaysConfig.openpay.merchantId);
        OpenPay.setApiKey(this.paymentGatewaysConfig.openpay.apiKey);
        OpenPay.setSandboxMode(this.paymentGatewaysConfig.openpay.sandbox);
        this.availablePayments.openpay = true;
      } else {
        console.error('No configuration or OpenPay does not loaded.');
        this.availablePayments.openpay = false;
      }

      if (this.paymentGatewaysConfig.mercadopago && Mercadopago) {
        Mercadopago.setPublishableKey(this.paymentGatewaysConfig.mercadopago.publicKey);
        this.availablePayments.mercadopago = true;
        this.getMPPaymentMethods();
      } else {
        console.error('No configuration or MercadoPago does not loaded.');
        this.availablePayments.mercadopago = false;
      }

      if (this.paymentGatewaysConfig.paypal === undefined) {
        console.error('No configuration or Paypal does not loaded.');
        this.availablePayments.paypal = false;
      } else {
        this.availablePayments.paypal = true;
      }

      if (!this.availablePayments.openpay && !this.availablePayments.paypal &&
        !this.availablePayments.mercadopago) {
        this.noPaymentsGateways = true;
        this.error = {
          title: 'Procesador de Pagos',
          message: '¡Lo sentimos! Ésta tienda aún no está configurada para recibir pagos.',
          okAction: () => {
            this.orderService.cancelOrder(this.orderId)
              .subscribe(() => {
                this.closeOrHome();
              });
          },
          showCancel: false
        };
        this.angulartics2.eventTrack.next({
          action: 'order',
          properties: {
            developerMessage: 'No payment gateways found',
            action: 'orderNoPaymentGatewaysFound',
            orderId: this.orderId
          }
        });
      }

      // Mostramos el botón de paypal si es la única opción
      if (!this.availablePayments.openpay && this.availablePayments.paypal) {
        this.activePanel = 'panelPaypal';
        this.paymentMethod = 'paypal';
        this.makePaypalButton();
      }
    }, () => {
      this.loadingConfig = false;
      this.noPaymentsGateways = true;
      this.error = {
        title: 'Procesador de Pagos',
        message: '¡Lo sentimos! Ésta tienda aún no está configurada para recibir pagos.',
        okAction: () => {
          this.orderService.cancelOrder(this.orderId)
            .subscribe(() => {
              this.closeOrHome();
            });
        },
        showCancel: false
      };
      this.angulartics2.eventTrack.next({
        action: 'order',
        properties: {
          developerMessage: 'No payment gateways found',
          action: 'orderNoPaymentGatewaysFound',
          orderId: this.orderId
        }
      });
    });
  }

  getMPPaymentMethods() {
    this.orderService.getMercadoPagoPayments()
      .subscribe((response) => {
        this.mpPaymentMethods = (response || [])
          .filter((pm) => {
            return (pm.payment_type_id !== 'debit_card' && pm.payment_type_id !== 'credit_card' &&
              pm.payment_type_id !== 'prepaid_card');
          });
      }, () => {
        this.mpPaymentMethods = [];
      });
  }

  onSelectMPPaymentMethod(method) {
    const schema = this.accountService.getAccessTokenDetails().aud;
    this.paying = true;
    this.mpPaymentMethod = method;
    this.orderService.orderPay({
      orderId: this.order.id,
      paymentType: 'offlinepayment',
      paymentMethodId: method.id,
      onlyRate: false,
      address: this.order.destination
    })
      .subscribe((paymentResponse) => {
        this.paying = true;
        this.orderCompleted = true;
        Swal.fire({
          type: 'success',
          title: 'Se ha generado el recibo de pago',
          html: '<p>¡Gracias por tu pedido! En breve recibirás un correo electrónico con los detalles de tu pedido ' +
            'y un <a href="' + paymentResponse.url + '" target="_blank">ticket que puedes imprimir</a> ' +
            'para realizar el pago en tiendas de conveniencia</p>'
        })
          .then(() => this.closeOrHome());
        this.angulartics2.eventTrack.next({
          action: 'order',
          properties: {
            developerMessage: 'Order ready for offline payment',
            action: 'orderOfflinePayment',
            orderId: this.orderId
          }
        });

        this.angulartics2.eventTrack.next({
          action: `${schema}_order_offlinePayment`,
          properties: {
            paymentInfo: {
              orderId: this.orderId
            }
          }
        });
      }, (error) => {
        this.angulartics2.eventTrack.next({
          action: 'order',
          properties: {
            developerMessage: 'Error paying the order',
            action: 'orderPaymentError',
            orderId: this.orderId,
            error: error
          }
        });

        this.angulartics2.eventTrack.next({
          action: `${schema}_order_paymentError`,
          properties: {
            paymentInfo: {
              errorMessage: 'Error paying the order',
              error: error
            }
          }
        });
        this.paying = false;
      });
  }

  onShowEdit(ev) {
    if (ev && ev.preventDefault) {
      ev.preventDefault();
    }
    if (this.addresses.shipping.length === 0) {
      this.getAddresses('shipping');
    }
    this.shippingSettings.countries = this.logisticsSettings.countries || [];
    this.shippingSettings.localPickups = this.logisticsSettings.settings.localPickups || {};
    this.editShipping = true;
    this.paymentMethod = null;
    this.activePanel = '';
    this.paypalButtonCreated = false;
    this.angulartics2.eventTrack.next({
      action: 'order',
      properties: {
        developerMessage: 'User wants to change shipping address',
        action: 'orderChangeShippingAddress',
        orderId: this.orderId
      }
    });
  }

  onEditBillingChange(ev) {
    if (ev && ev.preventDefault) {
      ev.preventDefault();
    }

    this.editBilling = !this.editBilling;
    if (this.addresses.billing.length === 0) {
      this.getAddresses('billing');
    }
    this.billingSettings.countries = this.logisticsSettings.countries || [];
    this.billingSettings.localPickups = { enabled: false };
  }

  getAddresses(type: string = 'shipping') {
    this.addressService.getAddresses(type)
      .subscribe((response) => {
        this.addresses[type] = response || [];
      }, (error) => {
        console.error('Can not get addresses', error);
        this.addresses[type] = [];
      });
  }

  findPickupLocation() {
    if (this.order.type === ORDER_TYPE_LOCAL_PICKUP && this.localPickupSettings &&
      this.localPickupSettings.locations && this.localPickupSettings.locations.length > 0) {

      this.localPickupLocation = this.localPickupSettings.locations
        .find((l) => l.addressLine1.replace(/\s+/g, '').toLowerCase() === this.order.destination.name);
      if (this.localPickupLocation === undefined) {
        console.error('The pickup location was not found');
      } else if (this.localPickupSettings.payment) {
        this.activePanel = 'panelLocalPickup';
        this.paymentMethod = 'localpickup';
      } else {
        this.paymentMethod = null;
        this.activePanel = '';
      }
    }
  }

  onDeleteBilling() {
    Swal.fire({
      title: '¿Estás seguro de que quieres eliminar los datos de facturación?',
      type: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, eliminar.',
      cancelButtonText: 'Cancelar'
    }).then((confirm) => {
      if (!confirm.value) {
        return;
      }
      this.updatingBilling = true;
      this.orderService.removeBilling(this.order.id)
        .subscribe((response: Order) => {
          this.order = response;
          this.editBilling = false;
          this.updatingBilling = false;
          this.postOrder();

          this.toastrService.success('Se ha quitado la información de facturación');
          this.angulartics2.eventTrack.next({
            action: 'order',
            properties: {
              developerMessage: 'User delete billing address',
              action: 'orderBillingAddressDelete',
              orderId: this.orderId
            }
          });
        }, (error) => {
          console.error('Error updating the order', error);
          const response = error.error || {};
          const message = response.msg || 'No se ha podido actualizar la dirección de facturación de la orden';
          this.toastrService.warning(message);
          this.updatingBilling = false;
          this.angulartics2.eventTrack.next({
            action: 'order',
            properties: {
              developerMessage: 'Error deleting billing address',
              action: 'orderBillingAddressDeleteError',
              orderId: this.orderId,
              error: error
            }
          });
        });
    });
  }

  onApplyBilling(ev) {
    // Run validations
    if (!this.billingAddress.billingDetails) {
      this.toastrService.error('Completa la información de facturación');
      return;
    }

    // RFC
    // The regex groups are:
    // 1) 3 or 4 chars for name
    // 2) Date (yymmdd)
    // 3) "homoclave" (2 chars)
    // 4) Verification digit or 'A'
    const rfcRegex = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
    this.billingAddress.billingDetails.rfc = this.billingAddress.billingDetails.rfc.trim().toUpperCase();
    if (!this.billingAddress.billingDetails.rfc || !this.billingAddress.billingDetails.rfc.match(rfcRegex)) {
      this.toastrService.error('Ingresa un RFC válido');
      return;
    }

    // Legal Name
    if (!this.billingAddress.billingDetails.legalName || this.billingAddress.billingDetails.legalName.length < 2) {
      this.toastrService.error('Ingresa una razón social.');
      return;
    }

    this.updatingBilling = true;
    this.billingAddress.type = 'billing';
    this.orderService.updateBilling(this.order.id, this.billingAddress)
      .subscribe((response: Order) => {
        this.order = response;
        this.editBilling = false;
        this.updatingBilling = false;
        this.postOrder();

        this.toastrService.success('Se ha actualizado la dirección de facturación');
        this.angulartics2.eventTrack.next({
          action: 'order',
          properties: {
            developerMessage: 'User changed billing address',
            action: 'orderBillingAddressChanged',
            orderId: this.orderId
          }
        });
      }, (error) => {
        console.error('Error updating the order', error);
        const response = error.error || {};
        const message = response.msg || 'No se ha podido actualizar la dirección de facturación de la orden';
        this.toastrService.warning(message);
        this.updatingBilling = false;
        this.angulartics2.eventTrack.next({
          action: 'order',
          properties: {
            developerMessage: 'Error changing billing address',
            action: 'orderBillingAddressChangeError',
            orderId: this.orderId,
            error: error
          }
        });
      });
  }

  onShippingChange(ev) {
    this.updatingShipping = true;
    this.orderService.updateDestination(this.order.id, this.address, this.address.isPickup)
      .subscribe((response) => {
        this.order = response;
        this.editShipping = false;
        this.updatingShipping = false;
        this.postOrder();

        this.toastrService.success('Se ha actualizado la dirección');
        this.angulartics2.eventTrack.next({
          action: 'order',
          properties: {
            developerMessage: 'User changed shipping address',
            action: 'orderShippingAddressChanged',
            orderId: this.orderId
          }
        });
      }, (error) => {
        console.error('Error updating the order', error);
        const response = error.error || {};
        const message = response.msg || 'No se ha podido actualizar la dirección de envío de la orden';
        this.toastrService.warning(message);
        this.updatingShipping = false;
        this.angulartics2.eventTrack.next({
          action: 'order',
          properties: {
            developerMessage: 'Error changing shipping address',
            action: 'orderShippingAddressChangeError',
            orderId: this.orderId,
            error: error
          }
        });
      });
  }

  onRewardsChange(rewards: OrderRewards) {
    this.angulartics2.eventTrack.next({
      action: 'order',
      properties: {
        developerMessage: 'User wants to change their rewards',
        action: 'orderRewardsChange',
        orderId: this.orderId,
        rewards: rewards
      }
    });
    this.orderService.updateRewards(rewards)
      .subscribe((response) => {
        this.order = response;
        this.postOrder();
        this.angulartics2.eventTrack.next({
          action: 'order',
          properties: {
            developerMessage: 'User changed their rewards',
            action: 'orderRewardsChanged',
            orderId: this.orderId,
            rewards: rewards
          }
        });
      }, (error) => {
        console.error('Can not update the order rewards', error);
        const message = error.error.msg || 'Hubo un problema al aplicar los cambios a la orden';
        Swal.fire({
          type: 'warning',
          title: 'No se han podido aplicar los cambios',
          html: `<p><strong>${message}</strong></p>`
        });
        this.order = Object.assign({}, this.order); // Tell child components to update
        this.angulartics2.eventTrack.next({
          action: 'order',
          properties: {
            developerMessage: 'Error changing order rewards',
            action: 'orderRewardsChangeError',
            orderId: this.orderId,
            rewards: rewards,
            error: error
          }
        });
      });
  }

  onPanelBeforeChange(ev: NgbPanelChangeEvent) {
    const pm = ev.panelId.toLowerCase().replace('panel', '');
    if (this.paymentMethod === pm) {
      ev.preventDefault();
      return;
    }
    this.paymentMethod = pm;
    this.payment = {
      cardNumber: '',
      cardName: '',
      cardExpiration: '',
      cardCvv: ''
    };

    this.paypalButtonCreated = (this.paymentMethod !== 'paypal');
    if (this.paymentMethod === 'paypal') {
      this.makePaypalButton();
    }

    this.angulartics2.eventTrack.next({
      action: 'order',
      properties: {
        developerMessage: 'User change payment method',
        action: 'orderPaymentMethod',
        orderId: this.orderId,
        method: pm,
      }
    });
  }

  onShowOPStores(modal, ev) {
    if (ev && ev.preventDefault) {
      ev.preventDefault();
    }

    this.modalService.open(modal, { size: 'lg', windowClass: 'modal-op-stores-map' });
  }

  getOpenPayMapURL() {
    let url = 'https://s3.amazonaws.com/public.openpay.mx/mapa-tiendas/index.html';
    url += '?address=' + [this.order.destination.addressLine1, this.order.destination.city].join(', ');
    url += '&postalCode=' + this.order.destination.postalCode;
    return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
  }

  onFinishOrder(ev) {
    const schema = this.accountService.getAccessTokenDetails().aud;
    this.angulartics2.eventTrack.next({
      action: `${schema}_order_paymentAttempt`,
      properties: {
        paymentInfo: {
          payment: this.payment
        }
      }
    });
    if (ev && ev.preventDefault) {
      ev.preventDefault();
    }

    // Local pickup
    if (this.paymentMethod === 'localpickup') {
      this.localPickupPayment();
      return;
    }

    // Validate card
    const paymentData = {
      cardNumber: this.payment.cardNumber.replace(/ /g, '').replace(/_/g, ''),
      cardName: this.payment.cardName.trim(),
      cardExpiration: this.payment.cardExpiration.replace(/ /g, ''),
      cardCvv: this.payment.cardCvv.trim(),
      expirationMonth: '01',
      expirationYear: '18'
    };
    if (paymentData.cardNumber.length < 12 || paymentData.cardName.length < 3 || paymentData.cardExpiration.length < 4 ||
      paymentData.cardCvv.length < 3) {
      Swal.fire({
        type: 'warning',
        title: 'Información de la tarjeta no válida',
        text: 'Revisa que la información proporcionada de la tarjeta sea válida.'
      });
      this.angulartics2.eventTrack.next({
        action: 'order',
        properties: {
          developerMessage: 'User wants to pay the order with invalid credit card data',
          action: 'orderPaymentValidationError',
          orderId: this.orderId
        }
      });

      this.angulartics2.eventTrack.next({
        action: `${schema}_order_paymentError`,
        properties: {
          paymentInfo: {
            error: 'Invalid card'
          }
        }
      });
      return;
    }
    const dates = paymentData.cardExpiration.split('/');
    paymentData.expirationMonth = dates[0].trim();
    paymentData.expirationYear = dates[1].trim();

    if (this.paymentMethod === 'creditcardmp') {
      this.mercadopagoPayment(paymentData);
      return;
    }

    if (!OpenPay) {
      this.toastrService.warning('OpenPay no está disponible');
      this.angulartics2.eventTrack.next({
        action: 'order',
        properties: {
          developerMessage: 'User wants to pay the order but Openpay is not available',
          action: 'orderPaymentOpenpayError',
          orderId: this.orderId
        }
      });

      this.angulartics2.eventTrack.next({
        action: `${schema}_order_paymentError`,
        properties: {
          paymentInfo: {
            error: 'Openpay not available'
          }
        }
      });
      return;
    }
    this.paying = true;
    OpenPay.token.create({
      card_number: paymentData.cardNumber,
      holder_name: paymentData.cardName,
      expiration_year: paymentData.expirationYear,
      expiration_month: paymentData.expirationMonth,
      cvv2: paymentData.cardCvv,
      address: {
        city: this.order.destination.city,
        state: this.order.destination.state,
        country_code: 'MX',
        line1: this.order.destination.addressLine1,
        postal_code: this.order.destination.postalCode
      }
    }, (response) => {
      const tokenId = response.data.id;
      const paymentInput = {
        orderId: this.order.id,
        paymentType: 'creditcard',
        onlyRate: false,
        sourceId: tokenId,
        deviceId: OpenPay.deviceData.setup(),
        address: this.order.destination,
        brand: response.data.card.brand,
        redirectUrl: '',
      };
      if (this.paymentGatewaysConfig.openpay.secure3d) {
        const tree = this.router.createUrlTree(['/transaccion']);
        const path = this.location.prepareExternalUrl(tree.toString());
        paymentInput.redirectUrl = window.location.origin + path;
      }
      this.orderService.orderPay(paymentInput)
        .subscribe((paymentResponse) => {
          if (!paymentResponse.paid) {
            // Check for 3D Secure
            const responseJson = this.parseJson(paymentResponse.gatewayResponse);
            if (responseJson.payment_method !== undefined &&
              responseJson.payment_method.url &&
              responseJson.payment_method.url.length > 2) {

              this.txId = responseJson.id || '';
              Swal.fire({
                title: 'Completar Pago',
                text: 'Serás redirigido al sitio web de tu banco para confirmar el pago',
                type: 'info'
              }).then(() => {
                this.open3dSecure(responseJson.payment_method.url);
              });
              return;
            }
            Swal.fire({
              title: 'Tu tarjeta ha sido rechazada',
              type: 'warning'
            });
            this.angulartics2.eventTrack.next({
              action: 'order',
              properties: {
                developerMessage: 'Payment declined',
                action: 'orderPaymentDeclined',
                orderId: this.orderId,
                gwResponse: paymentResponse
              }
            });

            this.angulartics2.eventTrack.next({
              action: `${schema}_order_paymentError`,
              properties: {
                paymentInfo: {
                  error: 'Card declined',
                  gwResponse: paymentResponse
                }
              }
            });
            this.paying = false;
            return;
          }
          this.orderCompleted = true;
          Swal.fire({
            type: 'success',
            title: '¡Pedido Completado!',
            html: '<p>¡Gracias por tu compra! En breve recibirás un correo electrónico con los detalles de tu compra.</p>'
          })
            .then(() => this.closeOrHome());
          this.angulartics2.eventTrack.next({
            action: 'order',
            properties: {
              developerMessage: 'Order paid!',
              action: 'orderPaid',
              orderId: this.orderId
            }
          });

          this.angulartics2.eventTrack.next({
            action: `${schema}_order_paymentSuccess`,
            properties: {
              paymentInfo: {
                orderId: this.orderId
              }
            }
          });
        }, (error) => {
          const data = error.data || {};
          const text = data.message || data.description || 'OpenPay rechazó la tarjeta y no dió detalles adicionales';
          Swal.fire({
            type: 'error',
            title: 'Problemas con el pago',
            text: text
          });
          this.angulartics2.eventTrack.next({
            action: 'order',
            properties: {
              developerMessage: 'Error paying the order',
              action: 'orderPaymentError',
              orderId: this.orderId,
              error: error
            }
          });
          this.angulartics2.eventTrack.next({
            action: `${schema}_order_paymentError`,
            properties: {
              paymentInfo: {
                errorMessage: 'Error paying the order',
                error: error
              }
            }
          });
          this.paying = false;
        });
    }, (error) => {
      const data = error.data || {};
      const text = data.message || data.description || 'OpenPay rechazó la tarjeta y no dió detalles adicionales';
      Swal.fire({
        type: 'error',
        title: 'Problemas con la Tarjeta',
        text: text
      });
      this.paying = false;
      this.angulartics2.eventTrack.next({
        action: 'order',
        properties: {
          developerMessage: 'Error paying the order',
          action: 'orderPaymentError',
          orderId: this.orderId,
          error: error,
          errorMessage: text
        }
      });

      this.angulartics2.eventTrack.next({
        action: `${schema}_order_paymentError`,
        properties: {
          paymentInfo: {
            errorMessage: 'Card declined',
            error: error
          }
        }
      });
    });
  }

  mercadopagoPayment(paymentData) {
    if (!Mercadopago) {
      this.toastrService.warning('MercadoPago no está disponible');
      return;
    }

    this.paying = true;
    const schema = this.accountService.getAccessTokenDetails().aud;
    const mpForm = document.createElement('form');
    [
      { name: 'cardNumber', value: paymentData.cardNumber },
      { name: 'securityCode', value: paymentData.cardCvv },
      { name: 'cardExpirationMonth', value: paymentData.expirationMonth },
      { name: 'cardExpirationYear', value: paymentData.expirationYear },
      { name: 'cardholderName', value: paymentData.cardName },
    ]
      .map((e) => {
        const i = document.createElement('input');
        i.setAttribute('data-checkout', e.name);
        i.setAttribute('value', e.value);
        i.setAttribute('type', 'text');
        return i;
      })
      .forEach((i) => {
        mpForm.appendChild(i);
      });
    const firstSix = paymentData.cardNumber.substring(0, 6);
    Mercadopago.getPaymentMethod({ bin: firstSix }, (status, response) => {
      if (status !== 200 || !Array.isArray(response) || response.length === 0) {
        Swal.fire({
          title: 'Revisa tu Tarjeta',
          type: 'warning',
          text: 'Hubo un problema validando el número de tu tarjeta'
        });
        this.paying = false;
        this.angulartics2.eventTrack.next({
          action: 'order',
          properties: {
            developerMessage: 'Error paying the order',
            action: 'orderPaymentError',
            orderId: this.orderId,
            error: response,
            errorMessage: `Error validating credit card number`
          }
        });

        this.angulartics2.eventTrack.next({
          action: `${schema}_order_paymentError`,
          properties: {
            paymentInfo: {
              errorMessage: 'Invalid card',
              error: `Error validating credit card number`
            }
          }
        });
        return;
      }
      const cardBrand = (response[0].name || response[0].id).toLowerCase();
      const paymentMethodId = response[0].id;
      const inputBin = document.createElement('input');
      inputBin.setAttribute('name', 'paymentMethodId');
      inputBin.setAttribute('type', 'hidden');
      inputBin.setAttribute('value', paymentMethodId);
      mpForm.appendChild(inputBin);

      // Generate token
      Mercadopago.createToken(mpForm, (mStatus, mResponse) => {
        const token = mResponse.id || '';
        Mercadopago.clearSession();
        if (mStatus !== 200 || token.length === 0 || firstSix !== mResponse.first_six_digits) {
          let mpError = mResponse.message || 'No hay detalles adicionales';
          if (mResponse.cause && Array.isArray(mResponse.cause)) {
            if (mResponse.cause.length > 0) {
              mpError = mResponse.cause[0].description || 'No hay detalles adicionales';
            }
          }
          Swal.fire({
            title: 'Problemas con la Tarjeta',
            type: 'error',
            html: `Tuvimos un problema procesando tu tarjeta. MercadoPago ha dicho lo siguiente: <br><br>` +
              `<small class="text-capitalize">${mpError}</small>`
          });
          this.angulartics2.eventTrack.next({
            action: 'order',
            properties: {
              developerMessage: 'Error paying the order',
              action: 'orderPaymentError',
              orderId: this.orderId,
              error: mResponse,
              errorMessage: mpError
            }
          });
          this.angulartics2.eventTrack.next({
            action: `${schema}_order_paymentError`,
            properties: {
              paymentInfo: {
                errorMessage: mpError,
                error: mResponse
              }
            }
          });
          this.paying = false;
          return;
        }
        const paymentInput = {
          orderId: this.order.id,
          paymentType: 'creditcardmp',
          paymentMethodId: paymentMethodId,
          onlyRate: false,
          sourceId: token,
          deviceId: '',
          address: this.order.destination,
          brand: cardBrand,
          redirectUrl: '',
        };
        this.orderService.orderPay(paymentInput)
          .subscribe((paymentResponse) => {
            if (!paymentResponse.paid) {
              const statusDetail = (paymentResponse.rejectedReason || '').trim().toLowerCase();
              let mpError = 'No hay detalles adicionales';
              if (MERCADO_PAGO_PAYMENT_MESSAGES.hasOwnProperty(statusDetail)) {
                mpError = MERCADO_PAGO_PAYMENT_MESSAGES[statusDetail].replace('#payment_method_id#', cardBrand);
                if (statusDetail === 'pending_contingency' || statusDetail === 'pending_review_manual') {
                  this.mercadopagoPendingPayment();
                  return;
                }
              }

              Swal.fire({
                title: 'Problemas con la Tarjeta',
                type: 'error',
                html: `Tuvimos un problema procesando tu tarjeta. MercadoPago ha dicho lo siguiente: <br><br>` +
                  `<small class="text-capitalize">${mpError}</small>`
              });
              this.angulartics2.eventTrack.next({
                action: 'order',
                properties: {
                  developerMessage: 'Payment declined',
                  action: 'orderPaymentDeclined',
                  orderId: this.orderId,
                  gwResponse: paymentResponse
                }
              });
              this.angulartics2.eventTrack.next({
                action: `${schema}_order_paymentError`,
                properties: {
                  paymentInfo: {
                    error: mpError,
                    gwResponse: paymentResponse
                  }
                }
              });
              this.paying = false;
              return;
            }
            this.orderCompleted = true;
            this.paying = false;
            Swal.fire({
              type: 'success',
              title: '¡Pedido Completado!',
              html: '<p>¡Gracias por tu compra! En breve recibirás un correo electrónico con los detalles de tu compra.</p>'
            })
              .then(() => this.closeOrHome());
            this.angulartics2.eventTrack.next({
              action: 'order',
              properties: {
                developerMessage: 'Order paid!',
                action: 'orderPaid',
                orderId: this.orderId
              }
            });

            this.angulartics2.eventTrack.next({
              action: `${schema}_order_paymentSuccess`,
              properties: {
                paymentInfo: {
                  orderId: this.orderId
                }
              }
            });
          }, (error) => {
            const data = error.data || {};
            const text = data.message || data.description || 'MercadoPago rechazó la tarjeta y no dió detalles adicionales';
            Swal.fire({
              type: 'error',
              title: 'Problemas con la Tarjeta',
              text: text
            });
            this.paying = false;
            this.angulartics2.eventTrack.next({
              action: 'order',
              properties: {
                developerMessage: 'Error paying the order',
                action: 'orderPaymentError',
                orderId: this.orderId,
                error: error,
                errorMessage: text
              }
            });
            this.angulartics2.eventTrack.next({
              action: `${schema}_order_paymentError`,
              properties: {
                paymentInfo: {
                  errorMessage: 'Card declined',
                  error: error
                }
              }
            });
          });
      });
    });
  }

  mercadopagoPendingPayment() {
    const schema = this.accountService.getAccessTokenDetails().aud;
    this.orderCompleted = true;
    this.paying = false;
    Swal.fire({
      type: 'warning',
      title: 'Se está procesando tu pago.',
      html: '<p>MercadoPago está procesando tu pago. Se te notificará el estado vía correcto electrónico</p>'
    })
      .then(() => this.closeOrHome());
    this.angulartics2.eventTrack.next({
      action: 'order',
      properties: {
        developerMessage: 'Payment in process',
        action: 'orderPendingPayment',
        orderId: this.orderId
      }
    });

    this.angulartics2.eventTrack.next({
      action: `${schema}_order_paymentPending`,
      properties: {
        paymentInfo: {
          orderId: this.orderId
        }
      }
    });
  }

  onDoPaymentConvStore() {
    const schema = this.accountService.getAccessTokenDetails().aud;
    this.paying = true;
    this.orderService.orderPay({
      orderId: this.order.id,
      paymentType: 'convstore',
      onlyRate: false,
      address: this.order.destination
    })
      .subscribe((paymentResponse) => {
        this.paying = true;
        this.orderCompleted = true;
        Swal.fire({
          type: 'success',
          title: 'Se ha generado el recibo de pago',
          html: '<p>¡Gracias por tu pedido! En breve recibirás un correo electrónico con los detalles de tu pedido ' +
            'y un <a href="' + paymentResponse.url + '" target="_blank">ticket que puedes imprimir</a> ' +
            'para realizar el pago en tiendas de conveniencia</p>'
        })
          .then(() => this.closeOrHome());
        this.angulartics2.eventTrack.next({
          action: 'order',
          properties: {
            developerMessage: 'Order ready for payment in convstore',
            action: 'orderConvstore',
            orderId: this.orderId
          }
        });

        this.angulartics2.eventTrack.next({
          action: `${schema}_order_convStore`,
          properties: {
            paymentInfo: {
              orderId: this.orderId
            }
          }
        });
      }, (error) => {
        this.angulartics2.eventTrack.next({
          action: 'order',
          properties: {
            developerMessage: 'Error paying the order',
            action: 'orderPaymentError',
            orderId: this.orderId,
            error: error
          }
        });

        this.angulartics2.eventTrack.next({
          action: `${schema}_order_paymentError`,
          properties: {
            paymentInfo: {
              errorMessage: 'Error paying the order',
              error: error
            }
          }
        });
        this.paying = false;
      });
  }

  localPickupPayment() {
    this.paying = true;
    this.orderService.orderPay({
      orderId: this.order.id,
      paymentType: 'localpickup',
      onlyRate: false,
      address: this.order.destination,
    }).subscribe((response) => {
      this.paying = false;
      this.orderCompleted = true;
      Swal.fire({
        type: 'success',
        title: '¡Pedido Realizado!',
        html: '<p>¡Gracias por tu pedido! En breve recibirás un correo electrónico con los detalles para la recolección.</p>'
      })
        .then(() => this.closeOrHome());
      this.angulartics2.eventTrack.next({
        action: 'order',
        properties: {
          developerMessage: 'Order placed!',
          action: 'orderPlaced',
          orderId: this.orderId
        }
      });
    }, (error) => {
      this.paying = false;
      const text = error.message || error.description || 'Hubo un problema finalizando el pedido.';
      Swal.fire({
        type: 'error',
        title: 'Problemas con el pedido.',
        text: text
      });
      this.angulartics2.eventTrack.next({
        action: 'order',
        properties: {
          developerMessage: 'Error placing the order',
          action: 'orderPlacementError',
          orderId: this.orderId,
          error: error,
          errorMessage: text
        }
      });
    });
    this.angulartics2.eventTrack.next({
      action: 'order',
      properties: {
        developerMessage: 'User wants to pay the order when picked up',
        action: 'orderPaymentPickup',
        orderId: this.orderId
      }
    });
  }

  makePaypalButton() {
    const schema = this.accountService.getAccessTokenDetails().aud;
    if (this.paypalButtonCreated) {
      return;
    }
    if (!paypal) {
      this.toastrService.warning('Paypal no está disponible');
      return;
    }
    this.creatingPaypalButton = true;
    setTimeout(() => this.creatingPaypalButton = false, 2000);

    // Make button
    setTimeout(() => { // Let call stack digest (button container added to the tree)
      paypal.Button.render({
        env: this.paymentGatewaysConfig.paypal.env,
        style: {
          size: 'medium',
          color: 'blue',
          shape: 'pill'
        },
        locale: 'es_ES',
        payment: (resolve, reject) => {
          this.orderService.confirmPayment({
            orderId: this.order.id,
            paymentType: 'paypal'
          }).subscribe((response) => {
            resolve(response.payPalId);
          }, (error) => {
            reject(error);
          });
          this.angulartics2.eventTrack.next({
            action: 'order',
            properties: {
              developerMessage: 'User wants to pay with Paypal',
              action: 'orderPaymentPaypal',
              orderId: this.orderId
            }
          });

          this.angulartics2.eventTrack.next({
            action: `${schema}_order_paymentAttempt`,
            properties: {
              paymentInfo: {
                paymentType: 'paypal'
              }
            }
          });
        },

        onAuthorize: (data, actions) => {
          this.orderService.executePaypal({
            orderId: this.order.id,
            paymentId: data.paymentID,
            payerId: data.payerID
          }).subscribe((response) => {
            this.orderCompleted = true;
            Swal.fire({
              type: 'success',
              title: '¡Pedido Completado!',
              html: '<p>¡Gracias por tu compra! En breve recibirás un correo electrónico con los detalles de tu compra.</p>'
            })
              .then(() => this.closeOrHome());
            this.angulartics2.eventTrack.next({
              action: 'order',
              properties: {
                developerMessage: 'Order paid with Paypal',
                action: 'orderPaidPaypal',
                orderId: this.orderId
              }
            });

            this.angulartics2.eventTrack.next({
              action: `${schema}_order_paymentSuccess`,
              properties: {
                paymentInfo: {
                  paymentType: 'paypal'
                }
              }
            });

          }, (error) => {
            const message = error.error.msg && error.error.msg.length > 0 ?
              `<br>Información adicional: <br><small>${error.error.msg}</small>` : '';
            Swal.fire({
              type: 'error',
              title: 'No se ha podido realizar el pago',
              html: `<p>No hemos recibido tu pago de Paypal. ${message}</p>`
            });
            console.error(error);
            this.angulartics2.eventTrack.next({
              action: 'order',
              properties: {
                developerMessage: 'Error paying the order with Paypal',
                action: 'orderPaymentPaypalError',
                orderId: this.orderId,
                error: error
              }
            });

            this.angulartics2.eventTrack.next({
              action: `${schema}_order_paymentError`,
              properties: {
                paymentInfo: {
                  errorMessage: 'Error paying with paypal',
                  error: error
                }
              }
            });
          });
        },

        onCancel: (data, actions) => {
          Swal.fire({
            type: 'error',
            title: 'No se ha podido realizar el pago',
            html: `<p>Se ha cancelado el pago con Paypal</p>`
          });
          this.angulartics2.eventTrack.next({
            action: 'order',
            properties: {
              developerMessage: 'User cancel Paypal payment',
              action: 'orderPaymentPaypalCancel',
              orderId: this.orderId
            }
          });
        },

        onError: (err) => {
          console.error('Error making paypal payment', err);
          Swal.fire({
            type: 'error',
            title: 'No se ha podido realizar el pago',
            html: `<p>Ha ocurrido un error al intentar realizar el pago.</p>`
          });
          this.angulartics2.eventTrack.next({
            action: 'order',
            properties: {
              developerMessage: 'Error paying the order with Paypal',
              action: 'orderPaymentPaypalError',
              orderId: this.orderId,
              error: err
            }
          });
          this.makePaypalButton();
        }
      }, '#paypal-button');
      this.paypalButtonCreated = true;
    }, 500);
  }

  open3dSecure(url) {
    this.modalWaitingRef = this.modalService.open(this.modalWaiting, { backdrop: 'static', keyboard: false });
    const validationWindow = window.open(url, 'mvtaValidateWindow', '');
    const pollTimer = setInterval(() => {
      if (validationWindow.closed !== false) {
        clearInterval(pollTimer);
        this.validatePayment();
      }
    }, 1000);
  }

  validatePayment() {
    const schema = this.accountService.getAccessTokenDetails().aud;
    this.modalWaitingRef.close();
    this.orderService.checkPaymentTxPaid(this.order.id, this.txId)
      .subscribe((response) => {
        this.paying = false;
        if (response.paid) {
          this.orderCompleted = true;
          Swal.fire({
            type: 'success',
            title: '¡Pedido Realizado!',
            html: '<p>¡Gracias por tu pedido!' +
              ' En breve recibirás un correo electrónico con los detalles para la recolección.</p>'
          })
            .then(() => this.closeOrHome());
          this.angulartics2.eventTrack.next({
            action: 'order',
            properties: {
              developerMessage: 'Order placed!',
              action: 'orderPlaced',
              orderId: this.orderId
            }
          });

          this.angulartics2.eventTrack.next({
            action: `${schema}_order_paymentSuccess`,
            properties: {
              paymentInfo: {
                orderId: this.orderId
              }
            }
          });
          return;
        }
        this.angulartics2.eventTrack.next({
          action: `${schema}_order_paymentError`,
          properties: {
            paymentInfo: {
              error: 'Invalid card'
            }
          }
        });
        Swal.fire({
          type: 'error',
          title: 'Problemas con la Tarjeta',
          text: 'No se ha recibido el pago'
        });
      });
  }

  closeOrHome() {
    this.angulartics2.eventTrack.next({
      action: 'order',
      properties: {
        developerMessage: 'User wants to close the checkout',
        action: 'closeWindow',
        orderId: this.orderId
      }
    });
    // This window is a popup?
    if (window['muventaPopup'] || (window.opener && window.opener !== window)) {
      window.close();
    } else {
      let redirectTo = sessionStorage.getItem('mvtaReferrer') || 'https://muventa.com';
      redirectTo = redirectTo.trim();
      if (redirectTo.match(/muventa\.com/gi)) {
        // Referrer can be an internal muventa URL, like "sales link"
        redirectTo = 'https://muventa.com';
        if (this.orderCompleted) {
          this.router.navigate(['/compra', this.order.id], { queryParams: { account: this.accountToken.aud } });
          return;
        }
      }
      window.location.href = redirectTo;
    }
  }

  parseJson(text) {
    try {
      const json = JSON.parse(text);
      return json;
    } catch (e) {
      return {};
    }
  }
}
