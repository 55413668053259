import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Angulartics2 } from 'angulartics2';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../../shared/auth/auth.service';
import { Cart } from '../cart.interface';
import { CartService } from '../cart.service';

@Component({
    selector: 'mvta-cart-customer',
    templateUrl: 'customer.component.html',
    providers: [CartService]
})
export class CartCustomerComponent implements OnInit {

    loggedIn = false;
    showLogin;
    invalidLogin = false;
    busy = false;
    loginPasswd = '';
    customerValue;

    @Input()
    cart: Cart;

    @Input()
    parentGroup: FormGroup;

    @Output()
    customerChange = new EventEmitter();

    @Input()
    get customer() {
        return this.customerValue;
    }
    set customer(val) {
        this.customerValue = val;
        this.customerChange.emit(this.customerValue);
    }

    @Input()
    get show() {
        return this.showLogin;
    }
    set show(val: boolean) {
        this.showLogin = val;
        this.showChange.emit(this.showLogin);
    }

    @Output()
    showChange = new EventEmitter();

    constructor(private authService: AuthService, private cartService: CartService,
        private toastrService: ToastrService, private angulartics2: Angulartics2) {
            this.customerValue = {};
            this.showLogin = false;
    }

    ngOnInit() {
        this.loggedIn = this.authService.isLoggedIn();
        if (this.loggedIn) {
            this.customerValue = this.authService.tokenDetails();
            this.customerChange.emit(this.customerValue);
        }
        this.authService.tokenChange.subscribe((token) => {
            if (token !== null) {
                this.customerValue = this.authService.tokenDetails();
                this.customerValue['isRegistered'] = true;
                this.customerChange.emit(this.customerValue);
                this.busy = false;
                this.loggedIn = true;
                this.onShowLogin(false);
            } else {
                this.customerValue = {};
                this.customerValue['isRegistered'] = false;
                this.customerChange.emit(this.customerValue);
                this.busy = false;
                this.loggedIn = false;
                this.onShowLogin(true);
            }
        });
        if (this.cart.customerEmail) {
            this.customerValue['userName'] = this.cart.customerEmail;
            this.customerValue['customerId'] = this.cart.customerId;
            if (!this.loggedIn) {
                this.showLogin = true;
                setTimeout(() => {
                    this.showChange.emit(this.showLogin);
                }, 500);
            }
        }
    }

    onChange(key, val) {
        this.customerValue[key] = val;
    }

    onShowLogin(status: boolean, ev = null) {
        this.preventDefault(ev);
        this.showLogin = status;
        this.showChange.emit(status);
    }

    onLogin(ev) {
        this.preventDefault(ev);
        this.busy = true;
        this.invalidLogin = false;
        const data = {
            userName: this.customerValue['userName'],
            passwd: this.loginPasswd
        };
        this.angulartics2.eventTrack.next({
            action: 'cart',
            properties: {
                developerMessage: 'User try to login',
                action: 'login',
                cartId: this.cart.id
            }
        });
        this.cartService.addUserToCart(data, this.cart.id)
            .subscribe((response) => {
                if (!response.jwt || response.jwt.length < 3) {
                    console.warn('JWT not valid, got:', response.jwt);
                    this.busy = false;
                    this.toastrService.warning('Combinación de usuario y contraseña incorrectos');
                    this.invalidLogin = true;
                    this.angulartics2.eventTrack.next({
                        action: 'cart',
                        properties: {
                            developerMessage: 'Invalid login',
                            action: 'loginError',
                            cartId: this.cart.id,
                            userName: data.userName
                        }
                    });
                    return;
                }
                this.authService.save(response.jwt);
                this.angulartics2.eventTrack.next({
                    action: 'cart',
                    properties: {
                        developerMessage: 'User logged in',
                        action: 'loggedIn',
                        cartId: this.cart.id,
                        userName: data.userName
                    }
                });
            }, (error) => {
                this.busy = false;
                this.toastrService.warning('Combinación de usuario y contraseña incorrectos');
                this.invalidLogin = true;
                this.angulartics2.eventTrack.next({
                    action: 'cart',
                    properties: {
                        developerMessage: 'Invalid login',
                        action: 'loginError',
                        cartId: this.cart.id,
                        userName: data.userName,
                        error: error
                    }
                });
            });
    }

    onLogout(ev) {
        this.preventDefault(ev);
        this.authService.logout();
        this.loggedIn = false;
        this.angulartics2.eventTrack.next({
            action: 'cart',
            properties: {
                developerMessage: 'User logged out',
                action: 'loggedOut',
                cartId: this.cart.id
            }
        });
    }

    preventDefault(ev) {
        if (ev && ev.preventDefault) {
            ev.preventDefault();
        }
    }
}
