import { EventEmitter, Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthService {
    private jwtHelper: JwtHelperService;
    public tokenChange = new EventEmitter();

    constructor() {
        this.jwtHelper = new JwtHelperService();
    }

    save(token) {
        sessionStorage.setItem(environment.sessionStorage.user , token);
        this.tokenChange.emit(token);
    }

    logout() {
        sessionStorage.removeItem(environment.sessionStorage.user);
        this.tokenChange.emit(null);
    }

    isLoggedIn() {
        const token = this.getToken();
        return (token.length > 10 && !this.isTokenExpired(token));
    }

    isTokenExpired(token: string): boolean {
        const expired = this.jwtHelper.isTokenExpired(token);
        if (expired) {
            this.logout();
        }

        return expired;
    }

    tokenDetails() {
        return this.isLoggedIn() ? this.jwtHelper.decodeToken(this.getToken()) : {};
    }

    tokenInHeader() {
        return 'Bearer ' + this.getToken();
    }

    getToken() {
        return sessionStorage.getItem(environment.sessionStorage.user) || '';
    }
}
