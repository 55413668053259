import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap/modal/modal';
import { Angulartics2 } from 'angulartics2';
import KeenTracking from 'keen-tracking';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AddressesService } from '../addresses/addresses.service';
import { ORDER_TYPE_LOCAL_PICKUP } from '../order/order.interface';
import { AccountService } from '../shared/account/account.service';
import { AuthService } from '../shared/auth/auth.service';
import { Address } from '../shared/interfaces/common.interface';
import { Cart, ERR_STOCK_NOT_ENOUGH } from './cart.interface';
import { CartService } from './cart.service';

@Component({
  selector: 'mvta-cart',
  templateUrl: 'cart.component.html',
  providers: [CartService, AddressesService]
})
export class CartComponent implements OnInit {

  cart: Cart;
  logisticsSettings;
  legalTerms;
  hasTerms = false;
  terms = { title: '', content: '' };
  acceptTerms = false;
  customer;

  address: Address;
  addresses: Array<Address>;
  addressSettings = {
    title: 'Dirección de envío',
    countries: [],
    localPickups: {},
    billing: false
  };

  accountToken;
  cartId: string;
  loadingCart = true;
  loggedIn = false;
  showLogin;
  formValidated = false;
  creatingOrder = false;
  error;

  constructor(private route: ActivatedRoute, private router: Router, private accountService: AccountService,
    private authService: AuthService, private toastr: ToastrService, private cartService: CartService,
    private modalService: NgbModal, private angulartics2: Angulartics2, private addressService: AddressesService) {
    this.accountToken = {};
    this.address = {};
    this.customer = {};
    this.error = {};
  }

  ngOnInit() {
    this.checkRouteParams();

    this.loggedIn = this.authService.isLoggedIn();
    this.authService.tokenChange.subscribe((token) => {
      this.loggedIn = token !== null;
      if (this.loggedIn) {
        // Get addresses
        this.addressService.getAddresses()
          .subscribe((response) => {
            this.addresses = response || [];
          }, (error) => {
            console.error('Can not get addresses', error);
            this.addresses = [];
          });
      }
    });
  }

  checkRouteParams() {
    forkJoin(
      this.route.queryParams.pipe(take(1)),
      this.route.params.pipe(take(1))
    ).subscribe((arrResponse) => {
      const queryParams = arrResponse[0];
      const params = arrResponse[1];

      // -- Query params
      const token = queryParams['token'] || '';
      const kioUid = queryParams['uid'] || '';
      if (kioUid.length > 0) {
        const sessionCookie = KeenTracking.utils.cookie('mvta-widget-kiouid');
        sessionCookie.set('user_id', kioUid);
      }
      if (token.length > 3) {
        this.accountService.setAccessToken(token);
      }
      const referredBy = queryParams['user'] || '';
      if (referredBy && referredBy.length > 3) {
        this.cartService.saveReferred(referredBy);
        this.angulartics2.eventTrack.next({
          action: 'cart',
          properties: {
            developerMessage: 'Cart has referred',
            action: 'referred',
            cartId: this.cartId,
            referredBy: referredBy
          }
        });
      }

      // Check if the token is valid
      if (!this.accountService.isAccessTokenValid()) {
        this.angulartics2.eventTrack.next({
          action: 'cart',
          properties: {
            developerMessage: 'No access token',
            action: 'noToken',
            cartId: this.cartId,
            token: this.accountService.getAccessToken()
          }
        });
        this.accountService.cleanAccessToken();
        this.router.navigate(['/mensajes/no-token']);
        return;
      }

      // If has token or referred, remove it from URL
      if (token.length > 3 || referredBy.length > 3) {
        const urlTree = this.router.parseUrl(this.router.url);
        delete urlTree.queryParams['token'];
        delete urlTree.queryParams['user'];
        delete urlTree.queryParams['uid'];
        this.router.navigateByUrl(urlTree);
      }
      this.accountToken = this.accountService.getAccessTokenDetails();

      // -- Params
      this.cartId = params['cartId'];
      this.getCartInfo();
      this.getLogisticsSettings();
      this.getLegalTerms();

      // -- Referrer
      const currentReferrer = sessionStorage.getItem('mvtaReferrer') || '';
      if (!currentReferrer || currentReferrer.length === 0) {
        sessionStorage.setItem('mvtaReferrer', document.referrer);
      }
    });
  }

  getCartInfo() {
    this.angulartics2.eventTrack.next({
      action: 'cart',
      properties: {
        developerMessage: 'Retrieving cart information',
        action: 'retrieve',
        cartId: this.cartId
      }
    });
    this.loadingCart = true;
    this.cartService.getAndValidateCart(this.cartId)
      .subscribe((response) => {
        this.cart = response;
        // The cart has items?
        if ((this.cart.items.length + this.cart.services.length) === 0) {
          this.loadingCart = false;
          this.error = {
            title: 'Carrito Vacío',
            message: 'El carrito no tiene artículos',
            okAction: () => this.closeOrHome(),
            showCancel: false,
            working: false
          };
          this.cart = undefined;
          this.loadingCart = false;
          this.angulartics2.eventTrack.next({
            action: 'cart',
            properties: {
              developerMessage: 'The cart is empty',
              action: 'noItems',
              cartId: this.cartId
            }
          });
          return;
        }
        this.loadingCart = false;
        this.angulartics2.eventTrack.next({
          action: 'cart',
          properties: {
            developerMessage: 'Cart Retrieved!',
            action: 'retrieved',
            cartId: this.cartId
          }
        });

        // Check if the logged user is the cart user
        if (this.loggedIn && this.authService.tokenDetails().user !== this.cart.customerId) {
          this.authService.logout();
          return;
        }

        // FIXME: Desmarcar cuando se usen los carritos v2
        // Marcar el carrito como en la pantalla de "shipping"
        // if (this.cart && this.cart.id && (!this.cart.status || this.cart.status === 'active')) {
        //   this.cartService.updateCartStatus('shipping_section', this.cart.id).subscribe();
        // }
      }, (error) => {
        console.error('Can not get the cart', error);
        this.error = {
          title: 'Carrito No Encontrado',
          message: 'No se ha encontrado el carrito',
          okAction: () => this.closeOrHome(),
          showCancel: false,
          working: false
        };
        if (error.error.code === ERR_STOCK_NOT_ENOUGH) {
          const message = (error.error.msg || '').replace(/\n/g, '<br>');
          this.error = {
            title: 'No Hay Inventario',
            message: message.length > 0 ? message : 'No hay inventario suficiente para los productos',
            question: '¿Deseas eliminarlo del carrito y continuar?',
            okAction: () => this.closeOrHome(),
            cancelAction: () => this.closeOrHome(),
            showCancel: true,
            working: false
          };

          // Remove for OK action
          if (message.length > 0 && message.indexOf('SKU')) {
            const arrMatches = /SKU: (.*)/g.exec(message);
            if (arrMatches.length > 1) {
              const sku = arrMatches[1].trim();
              this.error.okAction = () => {
                this.removeSkuAndUpdate(sku);
              };
            }
          }
        }

        this.loadingCart = false;
        this.angulartics2.eventTrack.next({
          action: 'cart',
          properties: {
            developerMessage: 'Error retrieving cart information',
            action: 'retrieveError',
            cartId: this.cartId,
            error: error
          }
        });
      });
  }

  removeSkuAndUpdate(sku: string) {
    this.error.working = true;
    this.cartService.getCart(this.cartId)
      .subscribe((response) => {
        const cart = response;
        if ((cart.items.length + cart.services.length) === 1) {
          this.toastr.warning('Es el único artículo en el carrito.');
          this.error.working = false;
          return;
        }
        cart.items = cart.items.filter((i) => i.sku !== sku);
        cart.services = cart.services.filter((s) => s.sku !== sku);
        this.cartService.updateCart(cart)
          .subscribe((responseUpdated) => {
            this.getCartInfo();
            this.toastr.success('Se ha eliminado el artículo del carrito');
            this.error = { working: false };
          }, (errorUpdating) => {
            console.error('Error updating the cart', errorUpdating);
            this.toastr.error('Ha ocurrido un error eliminando el artículo');
            this.error.working = false;
          });
      }, (error) => {
        console.error('Error getting the cart', error);
        this.toastr.error('Ha ocurrido un error eliminando el artículo');
        this.error.working = false;
      });
  }

  getLegalTerms() {
    this.cartService.getLegalTerms(this.accountToken.aud)
      .subscribe((response) => {
        this.legalTerms = response || {};

        // Some of the keys has content?
        this.hasTerms = Object.keys(this.legalTerms)
          .some((key) => this.legalTerms[key] && this.legalTerms[key].length);
      }, (error) => {
        this.angulartics2.eventTrack.next({
          action: 'cart',
          properties: {
            developerMessage: 'Error retrieving legal terms',
            action: 'retrieveLegalTermsError',
            accountId: this.accountToken.aud,
            cartId: this.cartId,
            error: error
          }
        });
      });
  }

  getLogisticsSettings() {
    this.accountService.logisticsSettingsChange.subscribe((response) => {
      if (response === null) {
        // Not loaded yet, wait until next event
        return;
      }
      this.logisticsSettings = response;

      // Has countries?
      if (this.logisticsSettings.countries === undefined || this.logisticsSettings.countries.length === 0) {
        Swal.fire({
          title: 'Lo sentimos, actualmente no aceptamos pedidos.',
          text: 'Por favor ponte en contacto con servicio a cliente, disculpa el inconveniente.',
          type: 'error'
        }).then(() => {
          this.router.navigate(['/mensajes/no-logistica']);
        });
        this.angulartics2.eventTrack.next({
          action: 'cart',
          properties: {
            developerMessage: 'No countries to ship',
            action: 'noCountriesToShip',
            accountId: this.accountToken.aud,
            cartId: this.cartId
          }
        });
        return;
      }

      // Has payment config? (gateways or localPickups)
      if ((this.logisticsSettings.payment === undefined || Object.keys(this.logisticsSettings.payment).length === 0) &&
        (this.logisticsSettings.settings === undefined || !this.logisticsSettings.settings.localPickups.payment)) {
        Swal.fire({
          title: 'Lo sentimos, actualmente no aceptamos pedidos',
          text: 'Hay un inconveniente con los procesadores de pagos. Por favor contacta a soporte, disculpa el inconveniente',
          type: 'error'
        }).then(() => {
          this.router.navigate(['/mensajes/no-metodos-pago']);
        });
        this.angulartics2.eventTrack.next({
          action: 'cart',
          properties: {
            developerMessage: 'No payment methods',
            action: 'noPaymentMethods',
            accountId: this.accountToken.aud,
            cartId: this.cartId
          }
        });
        return;
      }

      // Set settings for addresses
      this.addressSettings.countries = this.logisticsSettings.countries || [];
      this.addressSettings.localPickups = this.logisticsSettings.settings.localPickups || {};
      this.addressSettings = Object.assign({}, this.addressSettings); // Fire ng digest cycle
    }, (error) => {
      Swal.fire({
        title: 'Lo sentimos, actualmente no aceptamos pedidos.',
        text: 'Por favor ponte en contacto con servicio a cliente, disculpa el inconveniente.',
        type: 'error'
      }).then(() => {
        this.router.navigate(['/mensajes/no-logistica']);
      });
      this.angulartics2.eventTrack.next({
        action: 'cart',
        properties: {
          developerMessage: 'Error retrieving logistics settings',
          action: 'retrieveLogisticsSettingsError',
          accountId: this.accountToken.aud,
          cartId: this.cartId,
          error: error
        }
      });
    });
  }

  openTerms(modal, type: string, ev) {
    if (ev && ev.preventDefault) {
      ev.preventDefault();
    }
    const titles = {
      'conditions': 'Términos y condiciones',
      'refund': 'Política de Devoluciones',
      'privacy': 'Política de Privacidad'
    };
    this.terms.title = titles[type];
    this.terms.content = this.legalTerms[type].replace(/(?:\r\n|\r|\n)/g, '<br>');
    this.modalService.open(modal, { size: 'lg' });
    this.angulartics2.eventTrack.next({
      action: 'cart',
      properties: {
        developerMessage: 'User wants to see legal terms',
        action: 'seeLegalTerms',
        cartId: this.cartId,
        type: type,
        title: titles[type]
      }
    });
  }

  onItemsChange(items) {
    const cartToSave = Object.assign({}, this.cart);
    cartToSave.items = items;
    cartToSave.total = cartToSave.items.reduce((prev, item) => prev += item.quantity * item.price.actual, 0);
    this.angulartics2.eventTrack.next({
      action: 'cart',
      properties: {
        developerMessage: 'User wants to update their cart',
        action: 'update',
        cartId: this.cartId
      }
    });

    this.cartService.updateCart(cartToSave)
      .subscribe((response) => {
        this.cart = response;
        this.angulartics2.eventTrack.next({
          action: 'cart',
          properties: {
            developerMessage: 'User updated their cart',
            action: 'updated',
            cartId: this.cartId
          }
        });
      }, (error) => {
        console.error('Can not update cart');
        let errorMessage = 'No se ha podido actualizar el carrito';
        if (error.error.code === 'ERR-STOCK-NOT-ENOUGH') {
          errorMessage = error.error.msg;
        }
        this.toastr.error(errorMessage);
        // Send "done" signal to summary component changing reference of items
        this.cart.items = [].concat(this.cart.items.map((i) => Object.assign({}, i)));
        this.angulartics2.eventTrack.next({
          action: 'cart',
          properties: {
            developerMessage: 'Error updating user cart',
            action: 'updateError',
            cartId: this.cartId,
            error: error
          }
        });
      });
  }

  onSubmit(form, ev) {
    if (ev && ev.preventDefault) {
      ev.preventDefault();
    }
    this.formValidated = false;
    if (!this.address.isPickup && (!form || !form.valid)) {
      this.formValidated = true;
      this.toastr.warning('Revisa que todos los campos estén completos.');
      this.angulartics2.eventTrack.next({
        action: 'cart',
        properties: {
          developerMessage: 'User wants to create an order with invalid form',
          action: 'createOrderFormError',
          cartId: this.cartId,
          form: form
        }
      });
      return;
    }

    if (this.address.isPickup && (!this.address.name || !this.address.name.length)) {
      this.toastr.warning('Selecciona una dirección de recogida.');
      this.angulartics2.eventTrack.next({
        action: 'cart',
        properties: {
          developerMessage: 'User wants to create an order without destination',
          action: 'createOrderDestinationError',
          cartId: this.cartId,
          form: form
        }
      });
      return;
    }

    // Terms accepted?
    if (!this.acceptTerms && this.hasTerms) {
      this.toastr.warning('Para poder continuar es necesario que leas y aceptes los acuerdos legales');
      this.angulartics2.eventTrack.next({
        action: 'cart',
        properties: {
          developerMessage: 'User wants to create an order without accepting legal terms',
          action: 'createOrderLegalTermsUnaccepted',
          cartId: this.cartId
        }
      });
      return;
    }

    // The cart has an user?
    if (!this.authService.isLoggedIn()) {
      if (this.cart.customerEmail) {
        Swal.fire({
          title: 'Inicia Sesión',
          text: 'Éste carrito ya está asociado a un usuario, por favor inicia sesión.',
          type: 'warning'
        });
        this.angulartics2.eventTrack.next({
          action: 'cart',
          properties: {
            developerMessage: 'User wants to create an order but is not logged in and the cart has already an email',
            action: 'createOrderNotLoggedIn',
            cartId: this.cartId
          }
        });
        return;
      }

      this.creatingOrder = true;
      this.addUserToCart().subscribe(() => {
        this.createOrder();
      }, (error) => {
        this.creatingOrder = false;
      });
      return;
    }

    this.createOrder();
  }

  createOrder() {
    this.creatingOrder = true;
    const data = Object.assign({}, this.address);
    if (this.address.isPickup) {
      data['orderType'] = ORDER_TYPE_LOCAL_PICKUP;
    }
    if (this.cartService.getReferred().length > 0) {
      data['referredBy'] = this.cartService.getReferred();
    }

    this.angulartics2.eventTrack.next({
      action: 'cart',
      properties: {
        developerMessage: 'Creating order',
        action: 'createOrder',
        cartId: this.cartId
      }
    });

    this.cartService.createOrder(data, this.cartId)
      .subscribe((response) => {
        this.cartService.deleteReferred(); // Clean referred
        this.router.navigate(['/orden', response.id]);
        this.angulartics2.eventTrack.next({
          action: 'cart',
          properties: {
            developerMessage: 'Order created, redirecting to order',
            action: 'orderCreated',
            cartId: this.cartId,
            orderId: response.orderId
          }
        });
      }, (error) => {
        console.error('Can not create the order', error);
        Swal.fire({
          title: 'Error Creando Orden',
          text: 'Hubo un problema creando la orden, intente de nuevo',
          type: 'error'
        });
        this.creatingOrder = false;
        this.angulartics2.eventTrack.next({
          action: 'cart',
          properties: {
            developerMessage: 'There was an error creating order',
            action: 'createOrderError',
            cartId: this.cartId,
            error: error
          }
        });
      });
  }

  addUserToCart(): Observable<any> {
    return Observable.create((observer) => {
      // Validations
      if (this.customer['createAccount'] && (!this.customer['userName'] || !this.customer['newPasswd'])) {
        Swal.fire({
          title: 'Error de validación',
          text: 'Ingresa correo electrónico y contraseña',
          type: 'warning'
        });
        observer.error('Ingresa correo electrónico y contraseña');
        this.angulartics2.eventTrack.next({
          action: 'cart',
          properties: {
            developerMessage: 'Creating user validation error (incomplete data)',
            action: 'createUserValidationError',
            cartId: this.cartId,
            customer: this.customer
          }
        });
        return;
      }

      // Create account for user
      const data = {
        firstName: this.customer['firstName'],
        lastName: this.customer['lastName'],
        userName: this.customer['userName'],
        passwd: this.customer['newPasswd']
      };
      this.angulartics2.eventTrack.next({
        action: 'cart',
        properties: {
          developerMessage: 'Adding user to cart',
          action: 'addingUserToCart',
          cartId: this.cartId
        }
      });
      this.cartService.addUserToCart(data, this.cartId)
        .subscribe((response) => {
          if (!response.jwt || response.jwt.length < 3) {
            console.error('JWT not valid, got: ', response.jwt);
            this.toastr.warning('Combinación de usuario y contraseña incorrectos');
            this.angulartics2.eventTrack.next({
              action: 'cart',
              properties: {
                developerMessage: 'Error adding user to cart',
                action: 'addUserError',
                cartId: this.cartId,
                data: data,
                response: response
              }
            });
            return;
          }
          // Account created and jwt returned
          this.authService.save(response.jwt);
          observer.next(true);
          observer.complete();
          this.angulartics2.eventTrack.next({
            action: 'cart',
            properties: {
              developerMessage: 'User added to cart',
              action: 'userAdded',
              cartId: this.cartId,
              response: response
            }
          });
        }, (error) => {
          console.error('Error creating user', error);
          observer.error(error);

          // Scroll to login
          this.showLogin = true;
          const el = document.querySelector('#formShipping');
          if (el != null) {
            setTimeout(() => el.scrollIntoView({ block: 'start', behavior: 'smooth' }), 2000);
          }

          this.toastr.warning('El usuario ya existe. Inicia sesión');
          this.angulartics2.eventTrack.next({
            action: 'cart',
            properties: {
              developerMessage: 'Error creating user',
              action: 'createUserError',
              cartId: this.cartId,
              data: data,
              error: error
            }
          });
        });
    });
  }

  closeOrHome() {
    this.angulartics2.eventTrack.next({
      action: 'cart',
      properties: {
        developerMessage: 'User wants to close the checkout',
        action: 'closeWindow',
        cartId: this.cartId
      }
    });
    // This window is a popup?
    if (window['muventaPopup'] || (window.opener && window.opener !== window)) {
      window.close();
    } else {
      let redirectTo = sessionStorage.getItem('mvtaReferrer') || 'https://muventa.com';
      redirectTo = redirectTo.trim();
      if (redirectTo.match(/muventa\.com/gi)) {
        // Referrer can be an internal muventa URL, like "sales link"
        redirectTo = 'https://muventa.com';
      }

      window.location.href = redirectTo;
    }
  }

  triggerEvent() {
    const schema = this.accountService.getAccessTokenDetails().aud;
    this.angulartics2.eventTrack.next({
      action: `${schema}_order_payment_info`,
      properties: {}
    });
  }

}
