
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Angulartics2 } from 'angulartics2';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { take } from 'rxjs/operators';
import { CartService } from '../cart/cart.service';
import { AccountService } from '../shared/account/account.service';
import { AuthService } from '../shared/auth/auth.service';
import { ItemService } from './item.service';

@Component({
    selector: 'mvta-item',
    templateUrl: 'item.component.html',
    styleUrls: ['./item.component.scss'],
    providers: [ItemService, CartService]
})
export class ItemComponent implements OnInit {

    i18n = {lang: 'es', currency: 'mxn'};
    defaultImage = 'https://placehold.it/400x400?text=Sin+Imágen';
    loading = true;
    slug;
    product;
    productDescription;
    currentImage = '';
    currentImageIdx = -1;
    accountToken;
    variant;
    attrs;
    attrsValues;
    quantity = 1;
    working = false;
    images = [];

    constructor(private route: ActivatedRoute, private router: Router, private accountService: AccountService,
        private authService: AuthService, private itemService: ItemService, private cartService: CartService,
        private toastrService: ToastrService, private modalService: NgbModal, private domSanitizer: DomSanitizer,
        private angulartics2: Angulartics2) {
        this.accountToken = {};
        this.slug = '';
        this.productDescription = this.domSanitizer.bypassSecurityTrustHtml('');
        this.attrs = {};
        this.attrsValues = {};
    }

    ngOnInit() {
        this.checkRouteParams();
    }

    checkRouteParams() {
        forkJoin(
            this.route.queryParams.pipe(take(1)),
            this.route.params.pipe(take(1))
        ).subscribe((arrResponse) => {
            const queryParams = arrResponse[0];
            const params = arrResponse[1];

            // -- Query params
            const token = queryParams['token'] || '';
            if (this.accountService.getAccessToken() !== token && token.length > 3) {
                this.accountService.setAccessToken(token);
            }
            const referredBy = queryParams['user'] || '';
            if (referredBy && referredBy.length > 3) {
                this.cartService.saveReferred(referredBy);
                this.angulartics2.eventTrack.next({
                    action: 'itemDetail',
                    properties: {
                        developerMessage: 'Item has referred',
                        action: 'referred',
                        referredBy: referredBy
                    }
                });
            }

            // Check if the token is valid
            if (!this.accountService.isAccessTokenValid()) {
                this.angulartics2.eventTrack.next({
                    action: 'itemDetail',
                    properties: {
                        developerMessage: 'No access token',
                        action: 'noToken',
                        token: this.accountService.getAccessToken()
                    }
                });
                this.accountService.cleanAccessToken();
                this.router.navigate(['/mensajes/no-token']);
                return;
            }

            // If has token or referred, remove it from URL
            if (token.length > 3 || referredBy.length > 3) {
                const urlTree = this.router.parseUrl(this.router.url);
                delete urlTree.queryParams['token'];
                delete urlTree.queryParams['user'];
                this.router.navigateByUrl(urlTree);
            }
            this.accountToken = this.accountService.getAccessTokenDetails();

            // -- Params
            this.slug = params['slug'];
            this.getProductInfo();

            // -- Referrer
            sessionStorage.setItem('mvtaReferrer', document.referrer);
        });
    }

    getProductInfo() {
        this.loading = true;
        this.itemService.getProductBySlug(this.slug)
            .subscribe((response) => {
                const variants = response || [];
                if (variants.length === 0) {
                    console.error('No variants found', response);
                    return;
                }
                const master = variants.find((v) => v.master === true);
                this.product = Object.assign({}, master !== undefined ? master : variants[0]);
                this.product.variants = variants;
                this.product.images = [].concat.apply([], variants.map((v) => v.images).filter((v) => Array.isArray(v) && v.length > 0));
                this.product.image = this.product.images.length > 0 ? this.product.images[0] : this.defaultImage;
                this.productDescription = this.domSanitizer.bypassSecurityTrustHtml(this.product.description.es);
                this.currentImage = this.product.image;
                this.currentImageIdx = 0;

                // Remove image duplicates
                this.images = this.product.images.filter((i, idx, arr) => idx === arr.indexOf(i));
                if (this.images.length === 0) {
                    this.images.push(this.product.image);
                }

                // Attributes
                this.attrs = {};
                this.setAttrs(variants);

                // Pre-select master variant attrs
                Object.keys(this.product.attributes).filter((k) => {
                    return this.product.attributes[k].length > 0 && this.product.attributes[k] !== 'na';
                }).forEach((k) => {
                    this.attrsValues[k] = this.getAttrValue(this.product.attributes[k]);
                });

                this.findVariant();
                this.loading = false;
            }, (error) => {
                this.loading = false;
                this.router.navigate(['/mensajes/no-encontrado']);
            });
    }

    prevImage() {
        if (this.currentImageIdx > 0) {
            this.currentImageIdx--;
        }
        if (this.currentImageIdx < this.images.length) {
            this.currentImage = this.images[this.currentImageIdx];
        }
    }
    nextImage() {
        if (this.currentImageIdx < this.images.length - 1) {
            this.currentImageIdx++;
        }
        if (this.currentImageIdx < this.images.length) {
            this.currentImage = this.images[this.currentImageIdx];
        }
    }

    onChangeQty(increment = 1) {
        if ((this.quantity + increment) < 1) {
            this.quantity = 1;
            return;
        }
        this.quantity += increment;
    }

    onNext() {
        // Find selected variant
        this.findVariant();
        if (this.variant === undefined) {
            this.toastrService.warning('Selecciona los atributos del artículo');
            return;
        }

        this.variant.quantity = this.quantity;
        this.addToCart(this.variant);
    }

    onAttrChange(attr, val) {
        // Filter all variants where attr eq selected attr value
        const variants = this.product.variants.filter((v) => {
            const vValue = this.getAttrValue(v.attributes[attr]);
            if (vValue.length === 0) {
                return false;
            }

            return String(vValue) === String(val);
        });

        // Keep current attr values
        const currentAttr = Object.assign({}, this.attrs[attr]);
        this.attrs = {};
        this.setAttrs(variants);
        this.attrs[attr] = currentAttr;

        // Find variant
        this.findVariant();
    }

    addToCart(item) {
        this.working = true;
        this.itemService.addItemToCart({items: [this.mapItemToCartItem(item)]})
            .subscribe((response) => {
                this.toastrService.success('Se ha agregado el artículo al carrito');
                this.router.navigate(['/carrito', response.id]);
            }, (error) => {
                this.toastrService.error('Ha ocurrido un error intentado agregar el artículo');
                this.working = false;
                this.loading = false;
            });
    }

    findVariant() {
        this.variant = this.product.variants.find((v) => {
            let meetAll = true;
            Object.keys(this.attrsValues).forEach((k) => {
                const vValue = this.getAttrValue(v.attributes[k]);
                if (vValue !== this.attrsValues[k]) {
                    meetAll = false;
                }
            });

            return meetAll;
        });
    }

    mapItemToCartItem(item) {
        return {
            sku: item.sku,
            quantity: item.qty || item.quantity || 1,
            dateIn: '',
            nights: 0,
            beneficiaries: [],
            comments: '',

            name: item.name[this.i18n.lang],
            description: item.description[this.i18n.lang],
            image: item.images && item.images.length > 0 ? item.images[0] : this.defaultImage,
            slug: item.slug,
            attributes: item.attributes,
            price: {
                actual: item.price[this.i18n.currency],
                before: item.price['before' + this.i18n.currency.charAt(0).toUpperCase() + this.i18n.currency.slice(1)]
            },
            lang: this.i18n.lang,
            currency: this.i18n.currency
        };
    }

    setAttrs(variants) {
        const attrs = ['attribute1', 'attribute2', 'attribute3'];
        variants.forEach((v) => {
            attrs.forEach((a) => {
                if (v.attributes[a].length > 0 && v.attributes[a] !== 'na') {
                    if (!this.attrs.hasOwnProperty(a)) {
                        this.attrs[a] = {label: '', values: []};
                    }
                    const vLabel = this.getAttrLabel(v.attributes[a]);
                    const vValue = this.getAttrValue(v.attributes[a]);
                    if (vLabel.length === 0 || vValue === 0) {
                        console.warn('Configuración incorrecta de las variantes');
                        return;
                    }

                    this.attrs[a].label = vLabel;
                    const vIdx = this.attrs[a].values.findIndex((v1) => v1 === vValue);
                    if (vIdx === -1) {
                        this.attrs[a].values.push(vValue);
                    }
                }
            });
        });
        Object.keys(this.attrs).forEach((a) => {
            const aVal = String(this.attrsValues[a]);
            if (this.attrs[a].values.findIndex((b) => String(b) === aVal) === -1) {
                this.attrsValues[a] = '';
            }
        });
    }

    getAttrLabel(attr) {
        if (!attr || attr.length === 0) {
            return '';
        }
        const spl = attr.split('|') || [];

        return spl.length !== 2 ? '' : spl[0].trim();
    }

    getAttrValue(attr) {
        if (!attr || !attr.length) {
            return '';
        }
        const spl = attr.split('|') || [];

        return spl.length !== 2 ? '' : spl[1].trim();
    }
}
