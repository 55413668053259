import { Component } from '@angular/core';
import { Angulartics2Keen } from './shared/analytics/angulartics2-keenio';
import { DeviceInformationService } from './shared/analytics/device-information.service';

@Component({
  selector: 'mvta-root',
  template: '<router-outlet><spinner></spinner></router-outlet>'
})
export class AppComponent {
  constructor(
    private angulartics2KeenIO: Angulartics2Keen,
    private deviceInfo: DeviceInformationService,
  ) {
    angulartics2KeenIO.startTracking();
  }
}
