import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class PurchaseService {

    constructor(private http: HttpClient) { }

    getAccessToken(account: string): Observable<any> {
        return this.http.get(environment.apiUrl + '/accounts/v1/access/' + encodeURIComponent(account));
    }
}
