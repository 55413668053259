export const MERCADO_PAGO_PAYMENT_MESSAGES = {
  pending_contingency: 'Estamos procesando el pago. En menos de 2 días hábiles te enviaremos por e-mail el resultado.',
  pending_review_manual: 'Estamos procesando el pago. En menos de 2 días hábiles te diremos por e-mail si se acreditó ' +
    'o si necesitamos más información.',
  cc_rejected_bad_filled_card_number: 'Revisa el número de tarjeta.',
  cc_rejected_bad_filled_date: 'Revisa la fecha de vencimiento.',
  cc_rejected_bad_filled_other: 'Revisa los datos.',
  cc_rejected_bad_filled_security_code: 'Revisa el código de seguridad.',
  cc_rejected_blacklist: 'No pudimos procesar tu pago.',
  cc_rejected_call_for_authorize: 'Debes autorizar ante #payment_method_id# el pago de amount a Mercado Pago',
  cc_rejected_card_disabled: 'Llama a #payment_method_id# para que active tu tarjeta. El teléfono está al dorso de tu tarjeta.',
  cc_rejected_card_error: 'No pudimos procesar tu pago.',
  cc_rejected_duplicated_payment: 'Ya hiciste un pago por ese valor. Si necesitas volver a pagar usa otra tarjeta u otro medio de pago.',
  cc_rejected_high_risk: 'Tu pago fue rechazado. Elige otro de los medios de pago, te recomendamos con medios en efectivo.',
  cc_rejected_insufficient_amount: 'Tu #payment_method_id# no tiene fondos suficientes.',
  cc_rejected_invalid_installments: '#payment_method_id# no procesa pagos en cuotas.',
  cc_rejected_max_attempts: 'Llegaste al límite de intentos permitidos. Elige otra tarjeta u otro medio de pago.',
  cc_rejected_other_reason: '#payment_method_id# no procesó el pago.',
};
