import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';


@Injectable()
export class ItemService {

    constructor(private http: HttpClient) { }

    getProductBySlug(slug: string): Observable<any> {
        return this.http.get(environment.apiUrl + '/api/v1/store/product/' + slug);
    }

    addItemToCart(cart): Observable<any> {
        return this.http.put(environment.apiUrl + '/api/v1/cart', cart);
    }
}
