import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Item } from '../cart/cart.interface';
import { OrderRewards } from '../order/order.interface';
import { OrderService } from '../order/order.service';
import { AuthService } from '../shared/auth/auth.service';

@Component({
    selector: 'mvta-summary',
    templateUrl: 'summary.component.html',
    styleUrls: ['./summary.component.scss'],
    providers: [OrderService]
})
export class SummaryComponent implements OnInit, OnChanges {

    orderRewards: OrderRewards = {};
    applyingRewards = false;
    eWallet;
    poQty;
    editingItem;
    updatingQty = false;

    @Input()
    items;

    @Input()
    info;

    @Input()
    editItemsQty: boolean;

    @Output()
    orderRewardsChange: EventEmitter<OrderRewards> = new EventEmitter<OrderRewards>();

    @Output()
    itemsChange: EventEmitter<Item[]> = new EventEmitter<Item[]>();

    constructor(private toastrService: ToastrService, private orderService: OrderService, private authService: AuthService) {
        this.items = [];
        this.info = {};
        this.editItemsQty = false;
    }

    ngOnInit() {
        if (this.authService.isLoggedIn()) {
            this.getWallet();
        }
        this.authService.tokenChange.subscribe((token) => {
            if (token !== null && !this.eWallet) {
                this.getWallet();
            } else {
                this.eWallet = null;
            }
        });
    }

    getWallet() {
        this.orderService.customerEWallet()
            .subscribe((response) => {
                this.eWallet = response;
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.applyingRewards) {
            this.orderRewards = {};
            this.applyingRewards = false;
        }
        if (this.updatingQty) {
            this.poQty.close();
            this.updatingQty = false;
        }
    }

    onQtyClick(po, item) {
        this.poQty = po;
        if (po.isOpen()) {
            po.close();
            return;
        }
        this.editingItem = Object.assign({}, item);
        po.open();
    }

    onItemQtyChange() {
        if (Number.isNaN(this.editingItem.quantity) || this.editingItem.quantity < 1) {
            this.poQty.close();
            return;
        }
        const idx = this.items.findIndex((i) => i.sku === this.editingItem.sku);
        if (this.items[idx].quantity === this.editingItem.quantity) {
            this.poQty.close();
            return;
        }

        this.updatingQty = true; // Will set to false and close the popover in ngChanges once the parent update the items
        const copiedItems = [].concat(this.items.map((i) => Object.assign({}, i)));
        copiedItems[idx].quantity = this.editingItem.quantity;
        this.itemsChange.emit(copiedItems);
    }

    onApplyCoupon() {
        if (!this.orderRewards.coupon || this.orderRewards.coupon.length === 0) {
            this.toastrService.warning('Ingresa un cupón válido');
            return;
        }
        this.doApplyRewards();
    }

    onApplyReferral() {
        if (!this.orderRewards.referredBy || this.orderRewards.referredBy.length === 0) {
            this.toastrService.warning('Ingresa un código de recomendación válido');
            return;
        }
        this.doApplyRewards();
    }

    onApplyWallet() {
        const applied = this.info.eWallet || 0;
        const available = this.eWallet.available - applied;
        if (this.orderRewards.eWallet > available || this.orderRewards.eWallet <= 0) {
            this.toastrService.warning('Revisa el monto a aplicar');
            return;
        }
        this.doApplyRewards();
    }

    onRemoveCoupon(ev = null) {
        if (ev && ev.preventDefault) {
            ev.preventDefault();
        }
        this.orderRewards.coupon = '';
        this.info.coupons = '';
        this.doApplyRewards();
    }

    onRemoveReferral(ev = null) {
        if (ev && ev.preventDefault) {
            ev.preventDefault();
        }
        this.orderRewards.referredBy = 'none';
        this.info.referredBy = 'none';
        this.doApplyRewards();
    }

    onRemoveWalletCash(ev = null) {
        if (ev && ev.preventDefault) {
            ev.preventDefault();
        }
        this.orderRewards.eWallet = 0;
        this.info.eWallet = 0;
        this.doApplyRewards();
    }

    doApplyRewards() {
        this.orderRewards.orderId = this.info.id || '';
        this.orderRewards.coupon = this.orderRewards.coupon || this.info.coupons || '';
        this.orderRewards.eWallet = this.orderRewards.eWallet || this.info.eWallet || 0;
        this.orderRewards.referredBy = this.orderRewards.referredBy || this.info.referredBy || '';
        this.applyingRewards = true; // Wait until parent apply changes (changing some of the inputs)
        this.orderRewardsChange.emit(this.orderRewards);
    }

    drawAttributes(item): string {
        if (typeof item.attributes === 'string') {
            const ret = item.attributes.replace(/\|/g, ': ').replace(/- na/g, '').trim();
            if (ret === 'na') { // Single variant product
                return '';
            }
            return ret;
        }

        return Object.keys(item.attributes)
            .map((k) => item.attributes[k].replace(/\|/g, ': '))
            .filter((v) => v.length > 0 && v !== 'na')
            .join(' ');
    }
}
