import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgtUniversalModule } from '@ng-toolkit/universal';
import { TextMaskModule } from 'angular2-text-mask';
import { Angulartics2Module } from 'angulartics2';
import { ToastrModule } from 'ngx-toastr';
import { AddressesComponent } from './addresses/addresses.component';
import { AppComponent } from './app.component';
import { AppRoutes } from './app.routing';
import { CartComponent } from './cart/cart.component';
import { CartCustomerComponent } from './cart/customer/customer.component';
import { ItemComponent } from './item/item.component';
import { LayoutComponent } from './layout/layout.component';
import { MessagesComponent } from './messages/messages.component';
import { OrderComponent } from './order/order.component';
import { PurchaseComponent } from './purchase/purchase.component';
import { AccountService } from './shared/account/account.service';
import { DeviceInformationService } from './shared/analytics/device-information.service';
import { AuthService } from './shared/auth/auth.service';
import { InterceptorModule } from './shared/http/interceptor';
import { LoadingIndicatorComponent } from './shared/loading-indicator/loading-indicator.component';
import { KeysPipe } from './shared/pipes/object-to-array.pipe';
import { SpinnerComponent } from './spinner/spinner.component';
import { SummaryComponent } from './summary/summary.component';
import { WaitComponent } from './wait/wait.component';

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    LoadingIndicatorComponent,
    LayoutComponent,
    CartComponent,
    CartCustomerComponent,
    SummaryComponent,
    AddressesComponent,
    OrderComponent,
    PurchaseComponent,
    ItemComponent,
    MessagesComponent,
    WaitComponent,
    KeysPipe
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgtUniversalModule,
    RouterModule.forRoot(AppRoutes),
    NgbModule.forRoot(),
    ToastrModule.forRoot(),
    Angulartics2Module.forRoot({
      pageTracking: {
        clearQueryParams: true,
      }
    }),
    FormsModule,
    HttpClientModule,
    InterceptorModule,
    TextMaskModule
  ],
  providers: [
    AccountService,
    AuthService,
    DeviceInformationService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
