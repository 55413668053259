import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class AddressesService {

    constructor(private http: HttpClient) { }

    getAddresses(type: string = 'shipping'): Observable<any> {
        return this.http.get(environment.apiUrl + '/api/v1/address?type=' + type);
    }

}
