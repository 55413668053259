
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable()
export class MvtaInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = sessionStorage.getItem(environment.sessionStorage.accessToken) || false;
    const expections = [
      'googleapis.com',
      'blog-api',
      'https://qpxwtbshg5.execute-api.us-east-1.amazonaws.com',
      'https://ipapi.co/json',
    ];
    if (
      !expections.some((e) => req.url.indexOf(e) > -1) &&
      (token || this.authService.isLoggedIn())
    ) {
      let copyReq = req;
      if (token) {
        copyReq = req.clone({
          headers: req.headers.append('X-Access-Token', token)
        });
      }

      if (this.authService.isLoggedIn()) {
        copyReq = copyReq.clone({
          headers: copyReq.headers.append('Authorization', this.authService.tokenInHeader())
        });
      }

      return next.handle(copyReq).pipe(tap(this.handleResponse, this.handleError));
    }

    return next.handle(req).pipe(tap(this.handleResponse, this.handleError));
  }

  handleResponse(response) {
    if (response instanceof HttpResponse) { }
  }

  handleError(responseError) {
    if (responseError instanceof HttpErrorResponse) {
      // if (responseError.status === 403 || responseError.status === 401) {
      //     // Forbidden or Unauthorized
      //     sessionStorage.removeItem(environment.sessionStorage.accessToken);
      //     window.location.href = '/message/login';
      // }
    }
  }
}

@NgModule({
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: MvtaInterceptor, multi: true }
  ]
})
export class InterceptorModule { }
