import { Routes } from '@angular/router';
import { CartComponent } from './cart/cart.component';
import { ItemComponent } from './item/item.component';
import { LayoutComponent } from './layout/layout.component';
import { MessagesComponent } from './messages/messages.component';
import { OrderComponent } from './order/order.component';
import { PurchaseComponent } from './purchase/purchase.component';
import { WaitComponent } from './wait/wait.component';

export const AppRoutes: Routes = [{
    path: '',
    component: LayoutComponent,
    children: [
        {
            path: '',
            redirectTo: 'mensajes/404',
            pathMatch: 'full'
        },
        {
            path: 'item/:slug',
            component: ItemComponent,
            data: {
                title: 'Detalles del Artículo'
            }
        },
        {
            path: 'carrito/:cartId',
            component: CartComponent,
            data: {
                title: 'Información de Envío'
            }
        },
        {
            path: 'orden/:orderId',
            component: OrderComponent,
            data: {
                title: 'Finalizar Compra'
            }
        },
        {
            path: 'compra/:orderId',
            component: PurchaseComponent,
            data: {
                title: 'Detalles de la Compra'
            }
        },
        {
            path: 'mensajes/:messageId',
            component: MessagesComponent,
            data: {
                title: ''
            }
        },
        {
            path: 'transaccion',
            component: WaitComponent,
            data: {
                title: ''
            }
        },
        {
            path: '**',
            redirectTo: 'mensajes/404'
        }
    ]
}];
