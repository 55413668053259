import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Cart } from './cart.interface';

@Injectable()
export class CartService {

  constructor(private http: HttpClient) { }

  getCart(cartId: string): Observable<any> {
    return this.http.get(environment.apiUrl + '/api/v1/cart/' + cartId);
  }

  getAndValidateCart(cartId: string): Observable<any> {
    return this.http.get(environment.apiUrl + '/api/v1/cart/' + cartId + '/validate');
  }

  updateCart(cart: Cart): Observable<any> {
    return this.http.put(environment.apiUrl + '/api/v1/cart', cart);
  }

  getLegalTerms(accountId: string): Observable<any> {
    return this.http.get(environment.apiUrl + '/accounts/v1/' + accountId + '/terms');
  }

  addUserToCart(credentials, cartId: string): Observable<any> {
    return this.http.post(environment.apiUrl + '/api/v1/cart/' + cartId + '/user', credentials);
  }

  createOrder(data, cartId: string): Observable<any> {
    const send = Object.assign({}, data);
    delete send['createdAt'];
    delete send['updatedAt'];

    return this.http.post(environment.apiUrl + '/api/v1/order/from-cart/' + cartId, send);
  }

  saveReferred(referred: string) {
    sessionStorage.setItem(environment.sessionStorage.referred, referred);
  }

  deleteReferred() {
    sessionStorage.removeItem(environment.sessionStorage.referred);
  }

  getReferred(): string {
    return sessionStorage.getItem(environment.sessionStorage.referred) || '';
  }

  updateCartStatus(status: string, cartId: string): Observable<any> {
    const newStatusMap = { status };
    return this.http.put(`${environment.apiUrl}/api/v2/cart/${cartId}/status`, newStatusMap);
  }
}
