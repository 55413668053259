
export interface Attributes {
    attribute1?: string;
    attribute2?: string;
    attribute3?: string;
}

export interface Price {
    actual?: number;
    before?: number;
}

export interface Item {
    sku?: string;
    quantity?: number;
    dateIn: '',
    nights: 0,
    beneficiaries: string[],
    comments: '',
    name?: string;
    description?: string;
    image?: string;
    slug?: string;
    attributes?: Attributes;
    price?: Price;
    lang?: string;
    currency?: string;
}

export interface Cart {
    lastUpdated?: number;
    version?: number;
    id?: string;
    customerName?: string;
    customerId?: string;
    customerEmail?: string;
    currency?: string;
    total?: number;
    items?: Item[];
    services?: Item[];
    status?: string;
}

export const ERR_STOCK_NOT_ENOUGH = 'ERR-STOCK-NOT-ENOUGH';
