import {environment} from '../../../environments/environment';
import {Injectable, Inject} from '@angular/core';
import {Angulartics2} from 'angulartics2';
import { AuthService } from '../auth/auth.service';
import Fingerprint2 from 'fingerprintjs2';
import { LOCAL_STORAGE } from '@ng-toolkit/universal';
import KeenTracking from 'keen-tracking';
import { JwtHelperService } from '@auth0/angular-jwt';
import {Location} from '@angular/common';
import { AccountService } from '../account/account.service';


@Injectable({
    providedIn: 'root'
})
export class Angulartics2Keen {

    private jwtHelper: JwtHelperService;
    private keen;
    tries = 0;
    fingerprint;

    constructor(private angulartics2: Angulartics2, @Inject(LOCAL_STORAGE) private localStorage,
    private accountService: AccountService, private location: Location) {
        this.jwtHelper = new JwtHelperService();
        this.initializeKeen();

        if (window['requestIdleCallback']) {
            window['requestIdleCallback'](() => this.fingerprintReady());
        } else {
            setTimeout(() => this.fingerprintReady(), 2000);
        }
    }

    fingerprintReady() {

        this.fingerprint = this.localStorage.getItem(environment.localStorage.fingerprint);

        if (!this.fingerprint) {
            Fingerprint2.get((components) => {
                const values = components.map((c) => c.value);
                this.fingerprint = Fingerprint2.x64hash128(values.join(''), 31);
                this.localStorage.setItem(environment.localStorage.fingerprint, this.fingerprint);
            });
        }

    }

    startTracking() {
        this.angulartics2.pageTrack.subscribe((x: any) => this.pageTrack(x.path, x.location ));
        this.angulartics2.eventTrack.subscribe((x: any) => this.eventTrack(x.action, x.properties));
    }

    initializeKeen() {
        this.keen = new KeenTracking({
            projectId: environment.keenIO.projectId,
            writeKey: environment.keenIO.writeKey
        });

        this.keen.extendEvents(() => {
            return {
                fingerprint: this.fingerprint,
                app: {
                    schema: this.accountService.getAccessTokenDetails().aud,
                    environment: environment.production ? 'production' : 'sandbox',
                    productId: this.accountService.getAccessTokenDetails().aud
                }
            };
        });
    }


pageTrack(path: string, location: Location) {
    const schema = this.accountService.getAccessTokenDetails().aud;
        const addons = {
            addons : [{
              name: 'keen:referrer_parser',
              input: {
                referrer_url: 'referrer.full',
                page_url: 'url.full'
              },
              output: 'referrer.info'
            }]
    };


    this.keen.recordEvent(`${schema}_page_view`, {
        page: path,
        keen: addons
     });
}

eventTrack(action: string, properties: any) {
    console.log('Event tracked', action);
    if (properties !== null && typeof properties === 'object' &&
        !properties.hasOwnProperty('user') && this.isLoggedIn()) {
        const user = this.jwtHelper.decodeToken(this.localStorage.getItem(environment.localStorage.user));
        const userinfo = {
            id: user.user,
            email: user.email,
            name: user.firstName,
            tokenIssued: user.iat,
            expiration: user.exp
        };

        const addons = {
                addons : [{
                  name: 'keen:referrer_parser',
                  input: {
                    referrer_url: 'url.full',
                    page_url: 'url.full'
                  },
                  output: 'referrer.info'
                }]
        };

        properties.userinfo = userinfo;
        properties.keen = addons;
    }
    const sessionCookie = KeenTracking.utils.cookie('mvta-widget-kiouid');
    const uid = sessionCookie.get('user_id');
    properties.visitor = {guest_id: uid};
    if (!properties.hasOwnProperty('user')) {
        properties.user = {};
    }
    properties.user.uuid = uid;
    this.keen.recordEvent(action, properties);
}

    isLoggedIn() {
        const token = this.localStorage.getItem(environment.localStorage.user) || '';
        return (token.length > 10 && !this.jwtHelper.isTokenExpired(token));
    }

}


