import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Angulartics2 } from 'angulartics2';
import { Address } from '../shared/interfaces/common.interface';
import { AddressesService } from './addresses.service';

@Component({
  selector: 'mvta-addresses',
  templateUrl: 'addresses.component.html',
  providers: [AddressesService],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class AddressesComponent implements OnInit, OnChanges {

  @Input() addresses: Array<Address> = [];

  @Input() addressId: string;

  @Input() address: Address;
  @Output() addressChange = new EventEmitter();

  @Input() settings;

  loadingAddresses = false;
  loadingCountries = true;

  countries;
  regions;
  localPickupSettings;
  localPickup = false;
  localPickupLocation;

  constructor(private angulartics2: Angulartics2) {
    this.countries = [];
    this.regions = [];
    this.localPickupSettings = { enabled: false };
    this.address = this.defaultAddress();
    this.addressChange.emit(this.address);
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.address) {
      this.checkPickup();
    }

    if (changes.addresses) {
      this.setAddress();
    }

    if (changes.settings) {
      this.gotSettings();
    }
  }

  setAddress() {
    if (Array.isArray(this.addresses) && this.addresses.length > 0) {
      if (this.addressId !== undefined && this.addressId.length > 1) {
        this.address = this.addresses.find((a) => a.id === this.addressId);
      }
      if (!this.address || Object.keys(this.address).length === 0) {
        this.address = this.addresses[0];
      }

      if (this.address && Object.keys(this.address).length > 0) {
        this.onCountryChange(this.address.country);
        this.addressChange.emit(this.address);
      }
    } else if (!this.address || Object.keys(this.address).length === 0) {
      this.address = this.defaultAddress();
      this.onCountryChange(this.address.country);
      this.addressChange.emit(this.address);
    }
  }

  gotSettings() {
    if (this.settings !== undefined) {
      if (this.settings.countries !== undefined) {
        this.countries = this.settings.countries;
        this.onCountryChange(this.address.country);
      }
      if (this.settings.localPickups !== undefined) {
        this.localPickupSettings = this.settings.localPickups;
        this.checkPickup();
      }
    }
  }

  checkPickup() {
    if (this.address && this.address.isPickup) {
      this.localPickup = true;
      if (this.localPickupLocation === undefined && this.localPickupSettings.locations &&
        this.localPickupSettings.locations.length > 0) {

        this.localPickupLocation = this.localPickupSettings.locations
          .find((l) => l.addressLine1.replace(/\s+/g, '').toLowerCase() === this.address.name);
      }
    }
  }

  onLocalPickupChange(ev) {
    this.localPickup = !this.localPickup;
    this.address = this.defaultAddress();
    this.onCountryChange(this.address.country);
    this.address.isPickup = this.localPickup;
    this.addressChange.emit(this.address);
  }

  onAddressChange(ev) {
    this.address = ev;
    if (Object.keys(this.address).length === 0) {
      this.address = this.defaultAddress();
    }
    this.onCountryChange(this.address.country);
    this.addressChange.emit(this.address);
    this.angulartics2.eventTrack.next({
      action: 'cart',
      properties: {
        developerMessage: 'User change stored address',
        action: 'addressChange',
        address: ev
      }
    });
  }

  onPickupChange(ev) {
    this.localPickupLocation = ev;
    if (this.localPickupLocation !== undefined) {
      this.address = {
        name: this.localPickupLocation.name,
        addressLine1: this.localPickupLocation.addressLine1,
        addressLine2: this.localPickupLocation.addressLine2,
        addressLine3: this.localPickupLocation.addressLine3,
        crossStreet: this.localPickupLocation.crossStreet,
        reference: this.localPickupLocation,
        city: this.localPickupLocation.city,
        state: this.localPickupLocation.state,
        country: this.localPickupLocation.country,
        primaryPhone: this.localPickupLocation.primaryPhone,
        secondaryPhone: this.localPickupLocation.secondaryPhone,
        postalCode: this.localPickupLocation.postalCode,
        isPickup: true,
      };
      this.addressChange.emit(this.address);
    }
  }

  onCountryChange(ev) {
    this.angulartics2.eventTrack.next({
      action: 'cart',
      properties: {
        developerMessage: 'User change country',
        action: 'countryChange',
        country: ev
      }
    });
    const country = this.countries.find((c) => c.code === ev);
    if (!country) {
      this.regions.length = 0;
      return;
    }
    this.regions = [].concat(country.regions);
    if (ev !== this.address.country) {
      this.address.state = '';
    }
  }

  hasPhones(location): boolean {
    return this.isValidPhone(location.primaryPhone) || this.isValidPhone(location.secondaryPhone);
  }

  isValidPhone(phone: string = ''): boolean {
    return this.getPhone(phone).length > 6;
  }

  getPhone(phone: string = ''): string {
    return phone.replace(/\D/g, '');
  }

  defaultAddress(): Address {
    return {
      country: 'mex', // FIXME: Multiple countries
      state: '',
      billingDetails: {}
    };
  }
}
