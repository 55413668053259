import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import 'clientjs';
import { Subscription } from 'rxjs';
// import { ClientJS } from 'clientjs';
import { UAParser } from 'ua-parser-js';
import { environment } from '../../../environments/environment';
import { AccountService } from '../account/account.service';
declare let ClientJS: any;

export interface Geolocalization {
  asn?: string;
  city?: string;
  continent_code?: string;
  country?: string;
  country_area?: number;
  country_calling_code?: string;
  country_capital?: string;
  country_code?: string;
  country_code_iso3?: string;
  country_name?: string;
  country_population?: number;
  country_tld?: string;
  currency?: string;
  currency_name?: string;
  in_eu?: boolean;
  ip?: string;
  languages?: string;
  latitude?: number;
  longitude?: number;
  org?: string;
  postal?: string;
  region?: string;
  region_code?: string;
  timezone?: string;
  utc_offset?: string;
  version?: string;
}

export interface WebFingerprint {
  geo_province: string;
  geo_city: string;
  geo_country: string;
  geo_postal_code: string;
  geo_country_code: string;
  geo_continent: string;
  time_local_day_of_week_string: string;
  tech_device_type: string;
  tech_device_family: string;
  tech_browser_family: string;
  time_on_page: number;
  fingerprint: string;
  ecommerce_order_id: string;
}

export interface MuventaWebFingerprint {
  browser: MuventaWebFingerprintBrowser;
  os: MuventaWebFingerprintOS;
  device: MuventaWebFingerprintDevice;
  resolution: MuventaWebFingerprintResolution;
  timezone: string;
  timeOnPage: MuventaWebFingerprintTimeOnPage;
  lang: string;
  fingerprint: string;
  verificationNumber?: any;
  orderId?: string;
  secureCommerce?: MuventaWebFingerprintSecureCommerceDetails;
}

export interface MuventaWebFingerprintBrowser {
  name: string;
  version: string;
  majorVersion: string;
}
export interface MuventaWebFingerprintOS {
  name: string;
  version: string;
}

export interface MuventaWebFingerprintDevice {
  name: string;
  type: string;
  vendor: string;
  cpu: string;
  isMobile: string;
}

export interface MuventaWebFingerprintResolution {
  current: any;
  available: any;
}
export interface MuventaWebFingerprintTimeOnPage {
  minutes: number;
  seconds: number;
  milliseconds: number;
}
export interface MuventaWebFingerprintSecureCommerceDetails {
  mdp_id: string;
  ecommerce_order_id: string;
}

@Injectable()
// { providedIn: 'root' }
export class DeviceInformationService {

  private MAX_STORE_FINGERPRINT_RETRIES = 3;
  private loadDate;
  private clientJs;
  private geoLocalizationInfo: Geolocalization;
  private parser;
  private apiKey = null;
  private tokenChangeSubscription: Subscription = null;

  constructor(
    private http: HttpClient,
    private accountService: AccountService
  ) {

    this.loadDate = new Date();
    this.parser = new UAParser(window.navigator.userAgent).getResult();
    this.clientJs = new ClientJS();

    if (this.accountService.hasAccessToken()) {
      this.getApiKey();
    } else {
      this.tokenChangeSubscription = this.accountService.accountTokenChange.subscribe(() => {
        if (this.apiKey === null && this.accountService.hasAccessToken()) {
          this.getApiKey();
        }
      });
    }
  }

  private async getApiKey() {
    if (this.apiKey !== null) {
      return;
    }
    try {
      this.apiKey = await this.getSecureCommerceApiKey();
      if (this.tokenChangeSubscription !== null) {
        this.tokenChangeSubscription.unsubscribe();
        this.tokenChangeSubscription = null;
      }
    } catch (e) {
      console.error(e);
      this.apiKey = null;
    }
  }

  getSecureCommerceApiKey(): Promise<string> {
    return new Promise((resolve, reject) => {
      this.http.get<any>(`${environment.apiUrl}/api/v1/integration-settings/secure-commerce`)
        .subscribe((response) => {
          resolve(response.apiKey);
        }, (error) => {
          reject('SecureCommerce no está configurado para el comercio');
        });
    });
  }

  async getFingerprintProperties(): Promise<WebFingerprint> {
    const msDifference = new Date().getTime() - this.loadDate.getTime();
    // Para obtener los minutos dividimos la diferencia en milisegundos entre mil para obtener segundos
    // después los segundos entre 60 para obtener los minutos
    let seconds = msDifference / 1000;
    let minutes = seconds / 60;
    // Redondeamos a 2 decimales
    // Para redondear correctamente le sumamos epsilon a los minutos
    // Epsilon representa la diferencia entre 1 y el número decimal mas pequeño mayor que 1
    seconds = Math.round((seconds + Number.EPSILON) * 100) / 100;
    minutes = Math.round((minutes + Number.EPSILON) * 100) / 100;

    // Fingerprint: Usamos el fingerprint generado y almacenado en localstorage por Fingerprint2 en angulartics2
    // en caso de no haber sido generado usamos el generado por ClientJS
    let fingerprint = localStorage.getItem(localStorage.fingerprint) || '';
    if (fingerprint.length === 0) {
      fingerprint = this.clientJs.getFingerprint();
    }

    // Obtenemos la geolocalización y device data.
    this.geoLocalizationInfo = await this.getGeoLocalization();
    this.parser = new UAParser(window.navigator.userAgent).getResult();
    return {
      geo_province: this.geoLocalizationInfo.region,
      geo_city: this.geoLocalizationInfo.city,
      geo_country: this.geoLocalizationInfo.country,
      geo_postal_code: this.geoLocalizationInfo.postal,
      geo_country_code: this.geoLocalizationInfo.country_code,
      geo_continent: this.geoLocalizationInfo.continent_code,
      time_local_day_of_week_string: this.getDayOfWeekString(),
      tech_device_type:
        this.parser.device.type === undefined
          ? 'desktop'
          : this.parser.device.type,
      tech_device_family:
        this.parser.device.model === undefined
          ? 'Other'
          : this.parser.device.model,
      tech_browser_family: this.parser.browser.name,
      time_on_page: parseInt(seconds.toFixed(0), 10),
      fingerprint,
      ecommerce_order_id: '',
    };
  }

  async sendFingerprintToMuventa(orderId: string) {
    if (this.apiKey == null) {
      console.error('SecureCommerce no está configurado (missing API Key)');
      return;
    }
    let webFingerprint: WebFingerprint;
    webFingerprint = await this.getFingerprintProperties();
    webFingerprint.ecommerce_order_id = orderId;

    // Se envían los datos a máquinas de predicción.
    let mdpId = '';
    try {
      mdpId = await this.sendFingerprintToMDP(webFingerprint);
    } catch (e) {
      console.error(e);
      mdpId = '';
    }
    if (!mdpId) {
      return;
    }

    // Se envía el fingerprint a Muventa
    const muventaFingerprint = this.toMuventaWebFingerprint(webFingerprint);
    muventaFingerprint.secureCommerce = {
      mdp_id: mdpId,
      ecommerce_order_id: orderId
    };
    muventaFingerprint.orderId = orderId;
    try {
      await this.storeFingerprintInMuventa(muventaFingerprint);
    } catch (e) {
      console.error('No se ha podido guardar el fingerprint', e);
    }
  }

  private sendFingerprintToMDP(fingerprint: WebFingerprint): Promise<string> {
    return new Promise(async (resolve, reject) => {
      let retries = 0;
      let mdpId = '';
      while (retries < this.MAX_STORE_FINGERPRINT_RETRIES && !mdpId) {
        try {
          const mdpResponse = await this.storeFingerprintInMDP(fingerprint);
          mdpId = mdpResponse.id || '';
        } catch (e) {
          retries += 1;
          console.error(`Error guardando el fingerprint en MDP, intento ${retries} de ${this.MAX_STORE_FINGERPRINT_RETRIES}`);
          mdpId = '';
        }
      }

      if (!mdpId) {
        reject('No se pudo guardar el fingerprint en MDP');
        return;
      }

      resolve(mdpId);
    });
  }

  private storeFingerprintInMDP(mdpData: WebFingerprint): Promise<any> {
    const headers = new HttpHeaders({
      Authorization: this.apiKey,
    });
    return this.http.post(`${environment.mdpUrl}/fingerprints`, mdpData, { headers }).toPromise();
  }
  private storeFingerprintInMuventa(fingerprint: MuventaWebFingerprint): Promise<void> {
    return this.http.post<void>(`${environment.apiUrl}/api/v1/device-information`, fingerprint).toPromise();
  }

  private toMuventaWebFingerprint(fingerprint: WebFingerprint): MuventaWebFingerprint {
    return {
      browser: {
        name: fingerprint.tech_browser_family,
        version: '',
        majorVersion: '',
      },
      os: {
        name: '',
        version: '',
      },
      device: {
        name: fingerprint.tech_device_family,
        type: fingerprint.tech_device_type,
        vendor: '',
        cpu: '',
        isMobile: null,
      },
      resolution: {
        current: null,
        available: null,
      },
      timezone: '',
      timeOnPage: {
        minutes: 0,
        seconds: fingerprint.time_on_page,
        milliseconds: 0,
      },
      lang: '',
      fingerprint: fingerprint.fingerprint,
      verificationNumber: '',
    };
  }

  private resolutionToObject(res) {
    const parts = res.split('x');
    if (parts.length !== 2) {
      // Resolución no válida, se espera ###x###
      return { width: 0, height: 0 };
    }

    return {
      width: this.toNumber(parts[0]),
      height: this.toNumber(parts[1]),
    };
  }

  private toNumber(str): number {
    const strToParse = String(str);
    let num = 0;
    try {
      num = Number.parseFloat(strToParse);
    } catch (e) {
      return 0;
    }
    return num;
  }

  private getGeoLocalization(): Promise<Geolocalization> {
    return this.http.get<Geolocalization>('https://ipapi.co/json/').toPromise();
  }

  private getDayOfWeekString(): string {
    let date = new Date();
    let weekday = new Array(7);
    weekday[0] = 'Sunday';
    weekday[1] = 'Monday';
    weekday[2] = 'Tuesday';
    weekday[3] = 'Wednesday';
    weekday[4] = 'Thursday';
    weekday[5] = 'Friday';
    weekday[6] = 'Saturday';

    return weekday[date.getDay()];
  }
}
