import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { DeviceInformationService } from '../shared/analytics/device-information.service';
import { Address } from '../shared/interfaces/common.interface';
import { Destination, Order, OrderRewards } from './order.interface';

@Injectable()
export class OrderService {

  constructor(
    private http: HttpClient,
    private deviceInfo: DeviceInformationService,
  ) { }

  getOrder(orderId: string): Observable<any> {
    return this.http.get(environment.apiUrl + '/api/v1/order/' + orderId);
  }

  getPublicOrder(orderId: string): Observable<any> {
    return this.http.get(environment.apiUrl + '/api/v1/order/public/' + orderId);
  }

  cancelOrder(orderId: string): Observable<any> {
    return this.http.delete(environment.apiUrl + '/api/v1/order/' + orderId);
  }

  updateDestination(orderId: string, destination: Destination, isLocalPickup: boolean = false): Observable<any> {
    let url = environment.apiUrl + '/api/v1/order/' + orderId + '/destination';
    if (isLocalPickup) {
      url += '?isLocalPickup=true';
    }

    return this.http.put(url, destination);
  }

  updateBilling(orderId: string, address: Address): Observable<Order> {
    delete address['createdAt'];
    delete address['updatedAt'];

    return this.http.post<Order>(environment.apiUrl + '/api/v1/order/' + orderId + '/billing', address);
  }

  removeBilling(orderId: string): Observable<Order> {
    return this.http.delete<Order>(environment.apiUrl + '/api/v1/order/' + orderId + '/billing');
  }

  updateRewards(rewards: OrderRewards): Observable<any> {
    return this.http.post(environment.apiUrl + '/api/v1/order/rewards', rewards);
  }

  confirmPayment(data): Observable<any> {
    this.deviceInfo.sendFingerprintToMuventa(data.orderId);
    return this.http.post(environment.apiUrl + '/api/v1/order/pay', data);
  }

  executePaypal(data): Observable<any> {
    return this.http.post(environment.apiUrl + '/api/v1/paypal/hook', data);
  }

  orderPay(data): Observable<any> {
    this.deviceInfo.sendFingerprintToMuventa(data.orderId);
    return this.http.post(environment.apiUrl + '/api/v1/order/pay', data);
  }

  customerEWallet(): Observable<any> {
    return this.http.get(environment.apiUrl + '/api/v1/customer/me/ewallet');
  }

  checkPaymentTxPaid(orderId: string, txId: string): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v1/order/${orderId}/txstatus/${txId}`);
  }

  getMercadoPagoPayments(): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/v2/payments/mercadopago/payment-methods`);
  }

  removeAtKeys(addr) {
    const addrC = Object.assign({}, addr);
    if (addrC.updatedAt !== undefined) {
      delete addrC.updatedAt;
      delete addrC.createdAt;
    }
    if (addrC.shippingMethod !== undefined && addrC.shippingMethod.warehouseLocation) {
      delete addrC.shippingMethod.warehouseLocation.address.createdAt;
      delete addrC.shippingMethod.warehouseLocation.address.updatedAt;
    }

    return addrC;
  }
}
