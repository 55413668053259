import { EventEmitter, Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable()
export class AccountService {

    public accountTokenChange = new BehaviorSubject(null);
    public logisticsSettingsChange = new BehaviorSubject(null);
    private jwtHelper: JwtHelperService;
    private emitedFromLocal = false;

    constructor() {
        this.jwtHelper = new JwtHelperService();
    }

    hasAccessToken(): boolean {
        return !!this.getAccessToken();
    }

    isAccessTokenValid() {
        const token = this.getAccessToken();
        if (token.length > 3) {
            const decoded = this.jwtHelper.decodeToken(token);
            if (decoded.hasOwnProperty('exp')) { // Has expiration
                return !this.jwtHelper.isTokenExpired(token);
            }
            return true;
        }

        return false;
    }

    getAccessTokenDetails(): any {
        if (!this.isAccessTokenValid()) {
            this.cleanAccessToken();
            return {};
        }

        return this.jwtHelper.decodeToken(this.getAccessToken());
    }

    setAccessToken(token: string) {
        sessionStorage.setItem(environment.sessionStorage.accessToken, token);
        this.accountTokenChange.next(token);
    }

    getAccessToken(): string {
        const token = sessionStorage.getItem(environment.sessionStorage.accessToken) || '';
        if (!this.emitedFromLocal && token.length > 0) {
            this.emitedFromLocal = true;
            this.accountTokenChange.next(token);
        }
        return token;
    }

    cleanAccessToken() {
        sessionStorage.removeItem(environment.sessionStorage.accessToken);
        this.accountTokenChange.next(null);
    }
}
