import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { AccountService } from '../shared/account/account.service';
import { LayoutService } from './layout.service';

@Component({
    selector: 'mvta-layout',
    templateUrl: 'layout.component.html',
    styleUrls: ['./layout.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [LayoutService]
})
export class LayoutComponent implements OnInit {

    tokenDetails;
    logisticsSettings;
    details;
    title = 'Finalizar Compra';
    hasLogo = false;

    constructor(private accountService: AccountService, private router: Router, private route: ActivatedRoute,
        private layoutService: LayoutService) {

        this.tokenDetails = {};
        this.logisticsSettings = {};
        this.details = {};

        // Route Changes
        this.router.events.pipe(
            filter((ev) => ev instanceof NavigationEnd),
            map(() => this.route), // Return activated route
            map((r) => { // Find last activated route
                while (r.firstChild) {
                    r = r.firstChild;
                }
                return r;
            }),
            filter((r) => r.outlet === 'primary'),
            mergeMap((r) => r.data)
        )
        .subscribe((event: ActivatedRoute) => {
            this.title = 'Finalizar Compra';
            if (event.hasOwnProperty('title')) {
                this.title = event['title'];
            }
        });

        // Account token change
        this.accountService.accountTokenChange.subscribe((token) => {
            if (token === null) {
                this.details = {name: '', logo: ''};
                this.logisticsSettings = {details: this.details};
                this.accountService.logisticsSettingsChange.next(null);
                return;
            }
            this.tokenDetails = this.accountService.hasAccessToken() ? this.accountService.getAccessTokenDetails() : {};
            // Get account settings
            this.getLogisticsSettings();
        });
    }

    ngOnInit() {
        this.tokenDetails = this.accountService.hasAccessToken() ? this.accountService.getAccessTokenDetails() : {};
    }

    emitLogisticsSettings() {
        const details = this.logisticsSettings.details || {};
        details.name = this.tokenDetails.name || '';
        this.hasLogo = false;
        if (details.logo && details.logo.toUpperCase() !== 'ND' && details.logo.length > 2) {
            details.logo += details.logo.indexOf('?') === -1 ? '?' : '&';
            details.logo += 'alt=' + new Date().getTime();
            this.hasLogo = true;
        }
        this.details = details;
        this.logisticsSettings.details = details;

        this.accountService.logisticsSettingsChange.next(this.logisticsSettings);
    }

    getLogisticsSettings() {
        this.layoutService.getLogisticsSettings()
            .subscribe((response) => {
                this.logisticsSettings = response;
                this.emitLogisticsSettings();
            }, (error) => {
                console.error('Can not get account details', error);
                this.logisticsSettings = {};
                this.emitLogisticsSettings();
            });
    }
}
